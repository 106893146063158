import HttpService from './HttpService';

const baseUrlApi = process.env.REACT_APP_API_ADDRESS;
const baseUrlBuyer = process.env.REACT_APP_API_ADDRESS_BUYER;
const baseUrlSupplier = process.env.REACT_APP_API_ADDRESS_SUPPLIER;
const baseUrlFinance = process.env.REACT_APP_API_ADDRESS_FINANCE;
const generalApi = process.env.REACT_APP_GENERAL_API_ADDRESS;
const managementApi = process.env.REACT_APP_API_MANAGEMENT; 


export const endpoints = {
    login: '/users/authenticate',
    twoFactor: '/otp/validate',
    changePassword: '/suppliers',
    getCode: 'otp/send',
    validateOTP: 'otp/validate',
    signup: '/suppliers',
    provinces: '/provinces',
    companies: '/companies/kinds',
    docType: '/document-types',
    invoices: 'invoices',
    uploadInvoices: '/discounts',
    fetchUser: '/suppliers/current',
    financialInstitution : '/financial-institutions',
    fetchRisks: '/risks',
    sendResetPasswordCode:'/users/reset-password-token',
    sendForgotPasswordCode:'/users/forgot-password-token',
    resetPassword:'/users/reset-password',
    forgotPassword:'/users/forgot-password',
    assignmentDownloaded:'/assignment-downloaded',
    banksList:'/risks',

    uploadTemlikInvoice: (supplierId, discountId) => `/suppliers/${supplierId}/discounts/${discountId}/conveyance`,
    getDistrictsEndpoint: (provinceId) => `/provinces/${provinceId}/districts`,
    getTaxOfficesEndpoint: (provinceId) => `/provinces/${provinceId}/tax-administrations`,
    getDocsEndpoint: (supplierId) => `suppliers/${supplierId}/documents`,
    getDocDownloadEndpoint: (supplierId, docId) => `suppliers/${supplierId}/documents/${docId}`,
    getSupplierTitel: (taxId, provinceId, taxAdmin,securityCode) =>
        `/suppliers/title?taxId=${taxId}&provinceId=${provinceId}&taxAdministration=${taxAdmin}&securityCode=${securityCode}`,
       
    getFundDetailEndpoint: (fundId) => `/funds/${fundId}/sme-users`,
    getApproveFundEndpoint: (fundId) => `/funds/${fundId}/approves`,
    getFinancialDataDetailEndpoint: (financialDataId) => `/sme-users/financial-data/${financialDataId}`,
    
    supplierOnboarding: (supplierId) => `/users/${supplierId}/guide-status`,
    getUploadInvoices: (supplierId) => `/suppliers/${supplierId}/discounts`,
    buyersList: (supplierId) => `/suppliers/${supplierId}/buyers`,
    deleteDocs: (supplierId, documentId) => `/suppliers/${supplierId}/documents/${documentId}`,
    rates: (maturity) => `/rates?maturity=${maturity}`,
    businessDay: (day, month, year) => `/business-days/${day}.${month}.${year}`,

    zendeskRequest: '/requests',
};



export const api = new HttpService({ baseURL: `${baseUrlApi}` });
export const apiBuyer = new HttpService({ baseURL: `${baseUrlBuyer}` });
export const apiSupplier = new HttpService({ baseURL: `${baseUrlSupplier}` });
export const apiFinance = new HttpService({ baseURL: `${baseUrlFinance}` });
export const general = new HttpService({ baseURL: `${generalApi}` });
export const apiManagement = new HttpService({ baseURL: `${managementApi}` });



