import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, notification} from "antd";
import { useLocation } from "react-router-dom";
import { getUserDetailApi, updateUserApi} from "../../services/userAuth";
import {validateGsmNumber,validateEmail} from "../../helpers/validators"


const UserUpdate = () => {
  const location = useLocation();
  const userId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [userDetail, setUserDetail] = useState<any>();

// const  passwordPatternValidation = async (password:string) => {
//     const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!'+%&/()=?_*-.,])[A-Za-z\d@!'+%&/()=?_*-.,]{6,}$/); 
//     if(!regex.test(password)){
//       notification.warning({
//         message: `Şifre belirlerken; en az altı karakter, en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir.`,
//     });
//     }   
//     return regex.test(password);
//   };

const getUserData =  async () => {
  try {
    const response: any = await getUserDetailApi(userId);
    if (response) {
      setUserDetail(response?.user)
    }
    } catch (error: any) {
      console.log(error);
    }
}

  const onFinish =  async (values:any) => {
    try {
         const userUpdate : any = {
              phoneNumber : values.phoneNumber ? values.phoneNumber : userDetail?.phoneNumber,
              userRole: values.userRole ? values.userRole :  userDetail?.userRole,
            }
            await updateUserApi(userId, userUpdate);
              notification.success({
                  message: `Başarıyla güncellenmiştir.`,
              }); 
              getUserData();
              

      } catch (error: any) {
        console.log(error);
        if (error.data.Status === 404 && error.data.Type.includes('UserNotFoundException')) {
          notification.error({
            message: `Var olan şifrenizi yanlış girdiniz. `,
          });
        }
        if (error.data.Status === 409 && error.data.Type.includes('NewPasswordCannotBeOldPasswordException')) {
          notification.error({
            message: `Yeni şifreniz eski şifrenizle aynı olamaz. `,
          });
        }
        if (error.data.Status === 400 && error.data.Type.includes('IncorrectPasswordException')) {
          notification.error({
            message: `Şifre belirlerken; en az altı karakter, en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir.`,
          });
        }
      }
  };

  useEffect(() => {
    getUserData();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[])

 

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Kullanıcı Güncelleme</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
             <Form.Item 
              label="Kullanıcı:"
              name="email"
              rules={[
                {
                  validator: (_, value) => validateEmail(value ? value : userDetail?.email)
                },
              ]}
              >
              <Input placeholder={userDetail?.email} disabled={true}  />
            </Form.Item>
           
            <Form.Item 
              label="Telefon Numarası:"
              name="phoneNumber"
              rules={[
                {
                  validator: (_, value) => validateGsmNumber(value ? value : userDetail?.phoneNumber)
                },
              ]}
            >
              <Input placeholder={userDetail?.phoneNumber} />
            </Form.Item>
  
            <Form.Item
                  label="Kullanıcı Rolü"
                  name="userRole"
                  rules={[
                      {
                          message: 'Lütfen kullanıcı rolü seçiniz.',
                      },
                  ]}
              >
                  <Select placeholder={userDetail?.userRole === 'SUPERVISOR' ? 'ADMİN': 'KULLANICI'}  >
                      <Select.Option key="1" value="SUPERVISOR">ADMİN</Select.Option>
                      <Select.Option key="2" value="REPRESENTATIVE">KULLANICI</Select.Option>
                  </Select>
              </Form.Item>

            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Güncelle</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default UserUpdate;
