import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Spin,  notification } from "antd";
import { useLocation } from "react-router-dom";
import {  getTemplateContentApi, getTemplatesContentEditApi} from "../../services/managementApi";
import HtmlEditor from './HtmlEditor';

const TemplateEditContent = () => {
  const [form2] = Form.useForm();
  const location = useLocation();
  const url = location.pathname.split('/');
  const templateId = url[2];
  const { TextArea } = Input;

  const [htmlContent, setHtmlContent] = useState<any>();
  const [templateContent, setTemplateContent] = useState<any>([]);
  const [kindId, setKindId] = useState<string>("");
  const [formLayout, setFormLayout] = useState('horizontal');
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const [loading, setLoading] = useState(false);

  const getTemplateContent = async () => {
    try {
      setLoading(true)
      const response: any = await getTemplateContentApi(templateId);
      if(response){
        setLoading(false);
        setTemplateContent(response);
        setHtmlContent(response[0]?.contentText);
        setKindId(response[0]?.kindId);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

const setTemplatesContentEdit = async (val:any) => {
  setLoading(true);
  let data:any = {
    kindId: kindId,
    contents:[
      {
        languageCode: val.languageCode !== undefined ? val.languageCode : templateContent[0]?.languageCode,
        subject: val.subject !== undefined ? val.subject : templateContent[0]?.subject,
        content: kindId === "ce8ae9da-5b7c-36c3-9f29-29543a9af92d" ? htmlContent : val.subjectSms !== undefined ? val.subjectSms :  templateContent[0]?.contentText  ,
        html: kindId === "ce8ae9da-5b7c-36c3-9f29-29543a9af92d" ? true : false
      }
    ]
  }

  try {
    const response: any = await getTemplatesContentEditApi(templateId, data);
    if(response){
      getTemplateContent();
      form2.resetFields();
      setLoading(false);
      notification.success({
        message: `Başarıyla güncellenmiştir.`,
      });
    }
  } catch (e: any) {
    console.log(e)
    setLoading(false);
  }
}

  useEffect(() => {
    getTemplateContent();
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
 
  return (
    <main>
       <Spin spinning={loading} >
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Şablon İçerik Düzenle</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
            <Form
              form={form2}
              layout="vertical"
              onValuesChange={onFormLayoutChange}
              onFinish={setTemplatesContentEdit}
              style={{
                maxWidth: formLayout === 'inline' ? 'none' : 700,
              }}
              autoComplete="off"
            >
              <Form.Item
                label="Şablon Türü"
                name="html"
              >
                <Input placeholder={kindId === "ce8ae9da-5b7c-36c3-9f29-29543a9af92d" ? 'email' : 'sms'} disabled={true} />
              </Form.Item>

              <Form.Item
                label="Şablon Dil Kodu"
                name="languageCode"
              >
               <Input placeholder={templateContent[0]?.languageCode} disabled={true} />
               
              </Form.Item>

              <Form.Item
                name="subject"
                label='Şablon Konusu'
              >
                <Input placeholder={templateContent[0]?.subject} />
              </Form.Item>
              {
                kindId === "ce8ae9da-5b7c-36c3-9f29-29543a9af92d" ? (
                  <HtmlEditor setHtmlContent={setHtmlContent} htmlContent={htmlContent} /> 
                ): kindId === "19841e28-8968-36e7-8765-1aa605a7f5ff" ? (
                  <Form.Item
                    name="subjectSms"
                    label='Sms içerik'
                  >
                    <TextArea showCount maxLength={130}  placeholder={templateContent[0]?.contentText} style={{marginBottom:'20px'}}  />
                </Form.Item>
                ): ''

              }

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} >
                  Güncelle
                </Button>
              </Form.Item>
            </Form>
        </Col>
      </Row>
      </Spin>
    </main>
  );
};

export default TemplateEditContent;
