import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, notification} from "antd";
import { useLocation } from "react-router-dom";
import {  getBuyerUserApi, buyerUserUpdateApi} from "../../services/buyerApi";
import {validateGsmNumber,validateEmail} from "../../helpers/validators"


const BuyerUserUpdate = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const buyerUserId = location.pathname.split('/')[2];
  const [buyerUserInfo, setBuyerUserInfo] = useState<any>();

  const onFinish =  async (values:any) => {
    const userData : any = {
      email : values.email ? values.email : buyerUserInfo?.email,
      nameSurname : values.nameSurname ? values.nameSurname : buyerUserInfo?.nameSurname,
      phoneNumber : values.phoneNumber  ? values.phoneNumber : buyerUserInfo?.phoneNumber,
      title : values.title ? values.title : buyerUserInfo?.title, 
    };
      try {
          await buyerUserUpdateApi(userData, buyerUserId);
            notification.success({
              message: `Başarıyla yeni kayıt oluşturuldu.`,
          }); 
          form.resetFields();
          setTimeout(() => window.location.reload(), 1500);
         
        } catch (error: any) {
          console.log(error);
          if(error.status === 404 && error.data.Type.includes('BuyerUserNotFoundException')){
            notification.error({
              message: `Alıcının kullanıcısı bulunamadı.`,
          }); 
          }
        }
  };


const getBuyerUser =  async () => {
  try {
    const response: any = await getBuyerUserApi(buyerUserId);
    if (response) {
      setBuyerUserInfo(response.buyerUser);
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
      getBuyerUser();
       // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[])


  return (
    <main>
      <Row >
        <Col span={24}>
          <h3 className="page-title"> Alıcı Bilgi Güncelleme</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
           
            <Form.Item 
              label="Ad / Soyad:"
              name="nameSurname"
              >
              <Input placeholder={buyerUserInfo?.nameSurname}/>
            </Form.Item>
            <Form.Item 
              label="Görev / Ünvan:"
              name="title"
              >
              <Input placeholder={buyerUserInfo?.title} />
            </Form.Item>
            <Form.Item 
              label="E-posta Adresi:"
              name="email"
              rules={[
                {
                  validator: (_, value) => validateEmail(value ? value : buyerUserInfo?.email)
                },
              ]}
              >
              <Input placeholder={buyerUserInfo?.email}/>
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="phoneNumber"
              rules={[
                {
                  validator: (_, value) => validateGsmNumber(value ? value : buyerUserInfo?.phoneNumber)
                },
              ]}
            >
              <Input placeholder={buyerUserInfo?.phoneNumber}/>
            </Form.Item>
            
            
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Bilgileri Güncelle</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default BuyerUserUpdate;
