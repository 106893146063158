/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
  },
};
  
const urls = {
  login: '/login',

  passwordCreate: '/password/forget',
  forgetPassword: '/forgetpassword',
  twoFactor: '/twoFactor',

  home: '/',
  homePage: '/home-page',

  userTracing: '/user-tracing',
  newUser: '/newUser',
  userDetail: (id:any) => `/user-detail/${id}`,
  userUpdate: (id:any) => `/user-update/${id}`,
  
  accountTransactions: '/account-transactions',
  transactionsHistory: '/transactions-history',
  transactionsHistoryDetail: (id:any) => `/transactions-history-detail/${id}`,
  accountTransactionsDetail: (id:any) => `/account-transactions-detail/${id}`,
  accountTransactionsUpdate: (id:any) => `/account-transactions-update/${id}`,
  supplierRiskMovements: '/supplier-risk-movements',
  applicantSuppliers: '/applicant-suppliers',

  corporationSave: '/corporation-save',
  corporationsList: '/corporations-list',
  corporationUserSave: '/corporation-user-save',
  riskLimitInformation: '/risk-limit-Information',
  corporationsUsersList: (fundId:any) => `/corporations-users-list/${fundId}`,
  corporationsUserUpdate: (financeUserId:any) => `/corporations-user-update/${financeUserId}`,

  identification: '/identification',
  userIdentificationSave: '/user-identification-save',
  buyersList: '/buyers-list',
  buyerUploadInvoice: '/buyer-upload-invoice',
  buyerUploadSupplier: '/buyer-upload-supplier',
  buyerUsersList: (buyerId:any) => `/buyer-users-list/${buyerId}`,
  buyerUserDetail: (buyerId:any) => `/buyer-user-detail/${buyerId}`,
  buyerUpdate: (buyerId:any) => `/buyer-update/${buyerId}`,
  buyerUserUpdate: (buyerId:any) => `/buyer-user-update/${buyerId}`,
  buyerUserLimitUpdate: (buyerId:any) => `/buyer-user-limit-update/${buyerId}`,
  invoiceMaturityUpdate: '/invoice-maturity-update',

  invoicesstatus: '/invoices-status',
  editor:'/editor',
  templateWatch:'/template-watch-delete',
  templateCreate:'/template-create',
  templateEdit:'/template-edit',
  testMailSend:'/test-mail-send',
  dataTransfer:'/data-transfer',
};

export  { fakeAuthProvider, urls };
  