
// NUMBERS
export const decimalSeperator = ','; // 0,12
export const integerSeperator = '.'; // 100.000


export const convertNumberDotSeperated = (number: number) => {
    if (!number) return '0';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertFloatDotSeperated = (number: any) => {
    if (!number) return '0';
    const floatString = parseFloat(number).toFixed(2).replace('.', decimalSeperator);
    return floatString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};
export const convertCurrencyTl = (number: number) => `${convertFloatDotSeperated(number)} ₺`;

export const convertDotSeperatedToNumberString = (dotSeperated = '') => {
    const [integer = '', decimal = ''] = dotSeperated.split(decimalSeperator);
    const number = integer.split('.').join('');

    if (decimal) return `${number}.${decimal}`;

    return number;
};
export const convertDotSeperatedToFloat = (dotSeperated = '') =>
    parseFloat(convertDotSeperatedToNumberString(dotSeperated));

export const downloadFile = (fileContent: any) => {
    const byteStr = atob(fileContent);
    const arrayBuffer = new ArrayBuffer(byteStr.length);
    const int8Array: any = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteStr.length; i++) {
        int8Array[i] = byteStr.charCodeAt(i);
    }
    const fileUrl = URL.createObjectURL(
        new Blob([int8Array], {
        type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        })
    );
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "Fatura");
    document.body.appendChild(link);
    link.click();
}

export const mapArray = (array: any, key: any) =>
    array.reduce(
        (prevs: any, curObject: any) => ({
            ...prevs,
            [curObject[key]]: curObject,
        }),
        {}
    );

export const applicationStatuses = [
    {
        id: 1,
        value: 'TEKLIF_BEKLIYOR',
        color: '#31960e',
        text: 'Teklif Bekliyor',
        desc: 'Teklif Bekliyor',
    },
    { id: 2, 
        value: 'ISLEME_ALINDI', 
        color: '#0ee373', 
        text: 'İşleme Alındı',
        desc : 'İşleme Alındı.'
     },
    {
        id: 3,
        value: 'CEVAP_GELDI',
        color: '#b7b700',
        text: 'Cevap Geldi',
        desc: 'Cevap Geldi.',
    },
    {
        id: 4,
        value: 'TEKLIFI_ONAYLADIM',
        color: '#faad14',
        text: 'Teklifi Onayladım',
        desc: 'Teklifi Onayladım.',
    },
    {
        id: 5,
        value: 'SURESI_DOLDU',
        color: '#ff0000',
        text: 'Süresi Doldu',
        desc: 'Süresi Doldu.',
    },
    {
        id: 6,
        value: 'TEMLIKNAME_YUKLENDI',
        color: '#08d92c',
        text: 'Temlikname Yüklendi',
        desc: 'Temlikname Yüklendi.',
    },
    { id: 7, 
        value: 'IPTAL_EDILDI', 
        color: '#ff0000', 
        text: 'İptal Edildi',
        desc : 'İptal Edildi.'
     },
   
];
export const applicationStatusMapById = mapArray(applicationStatuses, 'id');
export const applicationStatusMapByValue = mapArray(applicationStatuses, 'value');

export const invoiceStatuses = [
    {
        id: 1,
        value: 'SECIM_YAPILMADI',
        color: '#ff000',
        text: 'Seçim yapılmadı',
        desc: 'Seçim yapılmadı',
    },
    { id: 2, 
        value: 'YUKLENDI', 
        color: '#b7b700', 
        text: 'Yüklendi',
        desc : 'İYüklendi.'
     },
    {
        id: 3,
        value: 'SECILDI',
        color: '#faad14',
        text: 'Teklifi Onayladım',
        desc: 'Teklifi Onayladım.',
    },
    {
        id: 4,
        value: 'SECILMEDI',
        color: '#faad14',
        text: 'Seçilmedi',
        desc: 'Seçilmedi.',
    },
    {
        id: 5,
        value: 'ODEME_BEKLENIYOR',
        color: '#ff0000',
        text: 'Ödeme bekleniyor',
        desc: 'Ödeme bekleniyor.',
    },
    {
        id: 6,
        value: 'ON_ODEME_YAPILDI',
        color: '#08d92c',
        text: 'Ön ödeme yapıldı',
        desc: 'Ön ödeme yapıldı.',
    },
    { id: 7, 
        value: 'ODENDI', 
        color: '#b7b700', 
        text: 'Ödendi',
        desc : 'Ödendi.'
     },
     { id: 8, 
        value: 'ODENMEDI', 
        color: '#ff0000', 
        text: 'Ödenmedi',
        desc : 'Ödenmedi.'
     },
   
];
export const invoiceStatusMapById = mapArray(invoiceStatuses, 'id');
export const invoiceStatusMapByValue = mapArray(invoiceStatuses, 'value');

export const statusInvoice = [
   { id: 1, 
        value: 'YUKLENDI', 
        color: '#0b3163', 
        text: 'Yüklendi',
        desc : 'Yüklendi.'
     },
   {
        id: 2,
        value: 'VADESI_GECTI',
        color: '#faad14',
        text: 'Vadesi Geçti',
        desc: 'Vadesi Geçti.',
    },
   {
        id: 3,
        value: 'ISKONTO_T_BULUNULDU',
        color: '#b7b700',
        text: 'İskonto Talebi Bulundu',
        desc: 'İskonto Talebi Bulundu.',
    },
   {
        id: 4,
        value: 'TEMLIK_EDILDI',
        color: '#0ee373',
        text: 'Temlik Edildi',
        desc: 'Temlik Edildi.',
    },
    {
        id: 5,
        value: 'REDDEDILDI',
        color: '#ff0000',
        text: 'Reddedildi',
        desc: 'Reddedildi.',
    },
    { id: 6, 
        value: 'ON_ODEME_YAPILDI', 
        color: '#08d92c', 
        text: 'Ön Ödeme Alındı',
        desc : 'Ön Ödeme Alındı.'
     },
   { id: 7, 
        value: 'ODENDI', 
        color: '#40a9ff', 
        text: 'Ödendi',
        desc : 'Ödendi.'
     },
   
   
];
export const statusInvoiceMapById = mapArray(statusInvoice, 'id');
export const statusInvoiceMapByValue = mapArray(statusInvoice, 'value');
