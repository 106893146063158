import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  notification,
  Layout,
  Button,
} from "antd";
import { twoFactor } from "../services/userAuth";
import { useSelector } from "react-redux";
import { LocalStorageService } from "../helpers/LocalStorage";
import { Content } from "antd/lib/layout/layout";
import logo from "../assets/logo.png";
import { urls } from "../router/auth";


const TwoFactor: React.VFC = () => {
  let navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(false);
  const userEmail = useSelector((state: any) => state.user);


  const onLogin = async (codeNo: string) => {
    const email: any = Object.values(userEmail)[0]
    const code: any =  Object.values(codeNo)[0]
    try{
      setIsLoading(true);
      if(code && userEmail){
        const data = {
          email, code
        }
        const response: any = await twoFactor(data);     
        if(response){
            LocalStorageService.setAuthToken(response?.token); 
            LocalStorageService.setUserRole(response?.userRole);
          setIsLoading(false);
          navigate("/");
        }
      }
    }
    catch (error: any) {
      setIsLoading(false);
      console.log(error)
      if(error.data.Status === 403 && error.data.Type.includes('IncorrectCodeException')){
        notification.error({message: 'Doğrulama kodunuz hatalıdır veya süresi dolmuştur.'});
      }
    }   
  };


  return (
    <Layout className="layout">
      <Row className="header-login"> 
          <div>
              <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
          </div>
      </Row>
      <Content className="content">
        <Row justify="center">
          <Col xs={24} md={12} lg={6}>
            <span className="loginSpan">Doğrulama Kodu</span>
            <Form name="login" onFinish={onLogin}>
              <Form.Item
                style={{ margin: "0px" }}
                className="email-input"
                name="codeNo"
                rules={[
                  {
                    required: true,
                    message: "Lütfen doğrulama kodunuzu giriniz!",
                  },
                ]}
              >
                <Input type="text"  />
              </Form.Item>           

              <Form.Item style={{marginTop: 30}}>
                <Button
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoding}
                  block
                >
                 Gönder
                </Button>
              </Form.Item>
            </Form>
            <Link to={urls.login}>Tekrar Doğrulama Kodu Al</Link>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default TwoFactor;
