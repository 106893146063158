import {apiManagement, api} from '../services/apis';

const setTemplatesNameApi = async (newData: any): Promise<Object> => {
  return (
    await apiManagement.post(`templates`,newData , {})
  );
};

const getTemplatesTypeApi = async (): Promise<Object> => {
  return await apiManagement.get(`kinds`,
    {}
  );
};

const setTemplatesContentApi = async (templateId: any, newData:any): Promise<Object> => {
  return (
    await apiManagement.post(`templates/${templateId}/contents`,newData , {})
  );
};

const getTemplatesNameApi = async (): Promise<Object> => {
  return await apiManagement.get(`templates`,
    {}
  );
};

const setTemplatesSaveApi = async (templateId:any,newData: any): Promise<Object> => {
  return (
    await apiManagement.post(`templates/${templateId}/variables`,newData , {})
  );
};

const getTemplatesNameEditApi = async (templateId:any,name: string): Promise<Object> => {
  return (
    await apiManagement.put(`templates/${templateId}`,{name} , {})
  );
};

const getTemplateContentApi = async (templateId:any): Promise<Object> => {
  return await apiManagement.get(`templates/${templateId}/contents`,
    {}
  );
};

const getTemplatesContentEditApi = async (templateId:any,newContent: string): Promise<Object> => {
  return (
    await apiManagement.put(`templates/${templateId}/contents`,newContent , {})
  );
};

const getTemplatesDeleteApi = async (templateId:any): Promise<Object> => {
  return (
    await apiManagement.delete(`templates/${templateId}` , {})
  );
};

const getTemplateVariableApi = async (templateId:any): Promise<Object> => {
  return await apiManagement.get(`templates/${templateId}/variables`,
    {}
  );
};

const getTemplateVariableUpdateApi = async (templateId:any,variablesId: any, newVariables:any): Promise<Object> => {
  return (
    await apiManagement.put(`templates/${templateId}/variables/${variablesId}`,newVariables , {})
  );
};

const getTemplateVariableDeleteteApi = async (templateId:any, variablesId: any): Promise<Object> => {
  return (
    await apiManagement.delete(`templates/${templateId}/variables/${variablesId}` , {})
  );
};

const sendTestMail = async (newData:any): Promise<Object> => {
  return (
    await api.post(`Test/mail`,newData , {})
  );
};

const getTemplateExportApi = async (): Promise<Object> => {
  return await apiManagement.downloadFile(`export`);
};

const getTemplateImportApi = async (file:any): Promise<Object> => {
  return (
    await apiManagement.post(`import`,file , {})
  );
};



export {
  setTemplatesNameApi,
  getTemplatesTypeApi,
  setTemplatesContentApi,
  getTemplatesNameApi,
  setTemplatesSaveApi,
  getTemplatesNameEditApi,
  getTemplateContentApi,
  getTemplatesContentEditApi,
  getTemplatesDeleteApi,
  getTemplateVariableApi,
  getTemplateVariableUpdateApi,
  getTemplateVariableDeleteteApi,
  sendTestMail,
  getTemplateExportApi,
  getTemplateImportApi
  
};
