import {general} from '../services/apis';

const getProvinces = async (): Promise<Object> => {
    return await general.get(`/provinces`,{}
    );
  };

 const getDistrictsApi = async (payload:any): Promise<Object> => {
    return await general.get(`/provinces/${payload}/districts`,{}
     );
};


const getTaxAdministartions = async (payload:any): Promise<Object> => {
    return await general.get(`/provinces/${payload}/tax-administrations`,{}
    );
  };

const getCompaniesApi = async (): Promise<Object> => {
    return await general.get(`/companies/kinds`,{}
    );
  };



export {
    getProvinces,
    getDistrictsApi,
    getTaxAdministartions,
    getCompaniesApi
}