import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, notification, Layout, Button} from "antd";
import { resetPasswordApi } from "../../services/userAuth";
import { Content } from "antd/lib/layout/layout";
import Logo from "../../assets/logo.png";
import {validatePassword} from '../../helpers/validators'

const CreatePassword: React.VFC = () => {
  let navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(false);
  const location = useLocation();
  const token = location.pathname.split('/');
  const userId = token[3]
  const passwordToken = token[4];

  const onResetPassword = async (args: any) => {
    try {
      setIsLoading(true);
      if(args.newPassword && args.confirmNewPassword && args.newPassword === args.confirmNewPassword ) {
        let data: any = {
          newPassword: args.newPassword,
          confirmNewPassword: args.confirmNewPassword,
          passwordToken: passwordToken,
        }
        await resetPasswordApi(data, userId );
        setIsLoading(false);
        navigate("/login");
      
      } else {
        setIsLoading(false);
        notification.warning({
          message: 'Şifre ve şifreyi onayla eşleşmiyor',
          duration: 3,
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      if(error.data.Status === 400 && error.data.Type.includes('PasswordTokenNotValidException')){
        notification.error({message: 'Yeni şifreniz eski şifrenizle aynı olamaz.'});
      }
    }
  };



  return (
    <Layout className="layout">
      <Row className="header-login"> 
          <div>
              <img height="40px" className="mb-big" src={Logo} alt="Depar.io" />
          </div>
      </Row>
      <Content className="content">
        <Row justify="center">
          <Col xs={24} md={16} lg={8}>
            <span className="loginSpan">Şifre Oluştur</span>
            <Form name="login" onFinish={onResetPassword}>
              <Form.Item
                style={{ margin: "0px" }}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Şifre"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "0px" }}
                name="confirmNewPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Şifreyi Onayla"
                />
              </Form.Item>
              <Form.Item style={{marginTop: 30}}>
                <Button
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoding}
                  block
                >
                  Şifremi Oluştur
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CreatePassword;
