import React, { useState, useEffect } from "react";
import { Row,Col, Table, Button, InputNumber, notification,Form, Collapse} from "antd";
import { getCorporationsListApi, updateBuyerLimitApi, updateBuyerValorApi } from "../../services/financeApi";
import { convertCurrencyTl } from "../../helpers/common";
import { LocalStorageService } from "../../helpers/LocalStorage";

function RiskLimitInformation() {
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(true);
  const [limit, setLimit] = useState('');
  const [valor, setValor] = useState(0);
  const [financialInstitutionsData, setFinancialInstitutionsData] = useState<any>([]);
  const [financialId, setFinancialId] = useState(0);
  

  let activeRow =  LocalStorageService.getActiveRow();
  const { Panel } = Collapse;

  const clearInput = (row:any) =>{
    form.setFieldValue(row.target.id, '');
  }

  const columns: any = [
    {
      title: "VKN",
      dataIndex: "taxId",
      key: "taxId",
      fixed: 'left',
      width:140,
    },
    {
      title: "Alıcı Ünvanı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Risk",
      dataIndex: "risk",
      key: "risk",
      width:150,
      render: convertCurrencyTl,
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
      key: 'limit', 
      width:300,    
      render: (value :any, row : any) => {
        return <Form form={form} className="table-limit-input" style={{ display : 'flex'}}>
                <Form.Item 
                      name={`${row.id}limit`} 
                      className='hide-arrow'
                    >
                    <InputNumber
                      formatter={(val) => ` ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",")} 
                      parser={(val:any) => val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")}
                      defaultValue={`${convertCurrencyTl(value)}`}                     
                      onChange={(val:any) => setLimit(val)} 
                      onClick={(row:any) => clearInput(row)}
                      className="no-spin input-width"                   
                      type='text'    
                      disabled={LocalStorageService.getUserRole() !== 'SUPERVISOR'}       
                  /> 
                </Form.Item>
                <Form.Item>              
                  <Button style={{marginLeft:'20px'}}  
                      onClick={() => updateBuyerLimit(limit, row) } 
                      className={`filter-row-button ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}
                      >
                        Güncelle</Button>
                </Form.Item>
            </Form>    
      }
     },
    {
      title: 'Valör Gün Sayısı',
      dataIndex: 'delayValue',
      key: 'delayValue',
      render: (value :any, row : any) => {
        return <Form form={form} className="table-limit-input" style={{ display : 'flex'}}>
                <Form.Item name={`${row.id}valor`} 
                  style={{ width:'60px'}}                 >
                <InputNumber
                  value={valor}               
                  placeholder={row.delayValue}  
                  name={row.id} 
                  type="number"
                  onBlur={(e:any) => setValor(e.target.value)} 
                  className="no-spin input-width"
                  max={3}
                  min={0}
                  disabled={LocalStorageService.getUserRole() !== 'SUPERVISOR'}
                />           
              </Form.Item>
              <Form.Item>
                <Button  style={{marginLeft:'20px'}}  
                    onClick={() => updateBuyerValor(valor, row) } 
                    className={`filter-row-button ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}
                    >
                      Güncelle</Button>
              </Form.Item>
            </Form>    
      }
    }, 
  ];  

 
  const getCorporationsList =  async () => {
    try {
      setLoading(true);
      const response: any = await getCorporationsListApi();
      if (response) {
        setLoading(false);
        setFinancialInstitutionsData(response.financialInstitutions);
        if(activeRow === null){
          setFinancialId(response.financialInstitutions[0]?.id)
        }else{
          setFinancialId(response.financialInstitutions[activeRow]?.id)
        }
        
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  const updateBuyerValor = async (value :any, row:any) => {    
      try{
        if(value !== ''){
          setValor(valor)
          await updateBuyerValorApi(row.id,financialId, valor);
          notification.success({message: 'Valor başarıyla güncellenmiştir.'});
           window.location.reload();  
        }
        else{
          notification.success({message: 'Güncellenecek valor değerini girmediniz.'});
        }
      }catch(e:any){
        console.log(e)
      }
    
    }

  const updateBuyerLimit = async (value :any, row:any) => { 
      try{
          const limit = value === '' ? row.limit : value;
          await updateBuyerLimitApi(row.id,financialId, limit);
            notification.success({message: 'Limit başarıyla güncellenmiştir.'});
            window.location.reload();  
            setLimit('')
      }catch(e:any){
        if(e.data.Status === 406 && e.data.Type.includes('BuyerLimitMustBeGreaterThanOrEqualTotalRiskException')){
          notification.error({message: 'Limit mevcut riskten az bir değer olamaz.'});
        }
      }
         
    }

  const financialData = (key: any | any[]) => {
    let number = parseInt(key);
      if(isNaN(number)){
        setFinancialId(key && financialInstitutionsData[0].id);
      }else{
        setFinancialId(key && financialInstitutionsData[key].id);
        LocalStorageService.setActiveRow(key)
      }
    }
  
  useEffect(() => {
    getCorporationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])

  useEffect(() => {
    if(activeRow){
      setFinancialId(financialInstitutionsData[activeRow]?.id);  
    }
  }, [activeRow])


  return (
    <main>
      <Row>
        <Col span={24} style={{marginBottom:'15px'}}>
          <h3 className="page-title">Risk/Limit Bilgileri </h3>
        </Col>
      </Row> 
      <Row>
        <Collapse accordion defaultActiveKey={activeRow === null ? 0 : activeRow}  
        onChange={financialData} style={{width:'100%'}}  >
          {
            financialInstitutionsData.map((item:any, index:any) => {
              return(
                <Panel header={item.name} key={index}>
                     <Table
                      className="w-100 invoiceTable"
                      pagination={{
                        position: ["bottomRight", "none" as any],
                        showLessItems: true,                          
                        showSizeChanger: true,
                        
                      }}
                      rowKey="id"
                      loading={Loading}
                      columns={columns}
                      dataSource={item.buyers?.filter((item: any) => !!item.isDefinedForFinancialInstitution )}
                      scroll={{
                        x: 1150,
                      }} 
                  />
                </Panel>
              )
            })
          }
        </Collapse>
      </Row>
    </main>
  );
};


export default RiskLimitInformation;
