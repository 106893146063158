import {apiFinance} from '../services/apis';

const getCorporationsListApi = async (): Promise<Object> => {
  return await apiFinance.get(`admin/financial-institutions`,
    {}
  );
};

const deleteFinance = async (financeId: any): Promise<Object> => {
  return await apiFinance.delete(`admin/financial-institution/${financeId}`,
    {}
  );
};

const getCorporationsUserApi = async (financeId: any): Promise<Object> => {
  return await apiFinance.get(`admin/financial-institutions/${financeId}/users`,
    {}
  );
};

const deleteFinanceUser = async (financeUserId: any): Promise<Object> => {
  return await apiFinance.delete(`admin/financial-institution-user/${financeUserId}`,
    {}
  );
};

const getFinanceUserInfoApi = async (financeUserId: any): Promise<Object> => {
  return await apiFinance.get(`admin/financial-institution-user/${financeUserId}`,
    {}
  );
};

const updateFinanceUserApi = async (financeUserId:any, newData: any): Promise<Object> => {
  return await apiFinance.put(`admin/financial-institution-user/${financeUserId}`, newData, {} );
};

const getFinanceInfoApi = async (financeId: any): Promise<Object> => {
  return await apiFinance.get(`admin/financial-institutions/${financeId}`,
    {}
  );
};

const updateFinanceApi = async (financeId:any, newData: any): Promise<Object> => {
  return await apiFinance.put(`admin/financial-institution/${financeId}`, newData, {} );
};

const financeUserSaveApi = async (financeId:any,newData: any): Promise<Object> => {
  return (
    await apiFinance.post(`admin/financial-institution/${financeId}/user`,newData , {})
  );
};

const financialInstitutionSaveApi = async (newData: any): Promise<Object> => {
  return (
    await apiFinance.post(`admin/financial-institution`,newData , {})
  );
};

const updateBuyerLimitApi = async (buyerId:any,financeId:any, limit: any): Promise<Object> => {
  return await apiFinance.patch(`admin/financial-institution/${financeId}/buyers/${buyerId}/limit`, {limit});
};

const updateBuyerValorApi = async (buyerId:any,financeId:any, delayValue: any): Promise<Object> => {
  return await apiFinance.patch(`admin/financial-institution/${financeId}/buyers/${buyerId}/delay-value`, {delayValue});
};

const discountApplicationCancel = async (applicationNumber:any): Promise<Object> => {
  return await apiFinance.patch(`admin/discount-application/${applicationNumber}/cancel`);
};

const forgotPasswordApi = async (data: any): Promise<Object> => {
  return (
    await apiFinance.post(`admin/send-email/create-or-reset-password`,data , {})
  );
};

export {
  getCorporationsListApi,
  deleteFinance,
  getCorporationsUserApi,
  deleteFinanceUser,
  getFinanceUserInfoApi,
  updateFinanceUserApi,
  getFinanceInfoApi,
  updateFinanceApi,
  financeUserSaveApi,
  financialInstitutionSaveApi,
  updateBuyerLimitApi,
  updateBuyerValorApi,
  discountApplicationCancel,
  forgotPasswordApi
};
