import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Steps, Input, Select, Divider, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import HtmlEditor from './HtmlEditor';
import { setTemplatesNameApi, getTemplatesTypeApi, setTemplatesContentApi, getTemplatesNameApi, setTemplatesSaveApi} from "../../services/managementApi";

const TemplateCreate = () => {
  const [isShowRow, setIsShowRow] = useState<any>([]);
  const [variable, setVariable] = useState<any>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [templateId, setTemplateId] = useState('');
  const [templateType, setTemplateType] = useState<any>([]);
  const [selectTemplateType, setSelectTemplateType] = useState<any>('');
  const [htmlContent, setHtmlContent] = useState<any>();
  const [templateName, setTemplateName] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const variables1: { name: string, description: string }[] = [];

  const { TextArea } = Input;
  const { Step } = Steps;
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };

  const getTemplateType = async () => {
    try {
      const response: any = await getTemplatesTypeApi();
      setTemplateType(response)
    } catch (e: any) {
      console.log(e)
    }
  }

  const showForm = () => {
    setIsShowRow([...isShowRow, { variableName: '', variableDefinition: '' }])
    variable[variable.length] = { variableName: '', variableDefinition: '' }
  }

  const handleSteps = (val: number) => {
    if (val > activeStep) {
      setActiveStep(val);
    } else {
      notification.success({
        message: `Bir önceki adıma dönemezsiniz.`,
      });
    }
  };

  const setTemplatesName = async (val: any) => {
    try {
      setLoading(true)
      const response: any = await setTemplatesNameApi(val);
      if (response) {
        setTemplateId(response.id);
        setActiveStep(1);
        setLoading(false)
      }
    } catch (e: any) {
      console.log(e)
      setLoading(false)
    }
  }

  const setTemplatesContent = async (val: any) => {
    const htmlText : any = templateType.filter((item: any) => item.id === val.html);
    let result = htmlText[0].id === "ce8ae9da-5b7c-36c3-9f29-29543a9af92d" ? true : false;
    let newData: any;
    newData = {
      kindId: htmlText[0].id,
      contents: [
        {
          languageCode: 'tr',
          subject: val.subject,
          content: result ? htmlContent : val.subjectSms,
          html: result
        }
      ]
    }

    try {
      setLoading(true)
      const response: any = await setTemplatesContentApi(templateId, newData);
      if (response) {
        setActiveStep(2);
        const res: any = await getTemplatesNameApi();
        setTemplateName(res.templates)
        setLoading(false)
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false)
    }
  }

  const setTemplatesSave = async (val:any) => {
    for (let key in val) {
      if (key.startsWith('variableName')) {
        const index:any = key.replace('variableName', '');
        if (!variables1[index]) {
          variables1[index] = { name: '', description: '' };
        }
        variables1[index].name = val[key];
      }
      
      if (key.startsWith('variableDefinition')) {
        const index:any = key.replace('variableDefinition', '');
        if (!variables1[index]) {
          variables1[index] = { name: '', description: '' };
        }
        variables1[index].description = val[key];
      }

    }
   
    const variables: any = [];
    variables1.map((item:any) => {
        return(
          variables.push({
        name: item.name,
        type: "string",
        description: item.description,
        required: true
      })
    )
    })
  
    
    if(variables.length>0){
      
      try{
        setLoading(true);
        const response: any = await setTemplatesSaveApi(templateId, {variables});
        if(response){
          notification.success({
            message: `Başarıyla şablon oluşturulmuştur.`,
          });
          form1.resetFields();
          form2.resetFields();
          form3.resetFields();
          setActiveStep(0);
          setHtmlContent('');
          setVariable([]);
          setIsShowRow([]);
          setLoading(false)
        }
      }catch(e:any){
        console.log(e)
      }
    }
  }

  useEffect(() => {
    getTemplateType()
  }, [])
 
  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Şablon Oluştur</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Steps direction="vertical" current={activeStep} onChange={handleSteps}>
            <Step title="Şablon Oluştur"
              description={
                activeStep === 0 ? (
                  <Form
                    form={form1}
                    layout="vertical"
                    onValuesChange={onFormLayoutChange}
                    onFinish={setTemplatesName}
                    style={{
                      maxWidth: formLayout === 'inline' ? 'none' : 600,
                    }}
                    autoComplete="off"
                  >
                    <Form.Item
                      name="name"
                      label='Şablon Adı'
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen Şablon adını giriniz.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }}>
                        İleri
                      </Button>
                    </Form.Item>
                    <Divider />
                  </Form>
                ) : ('')
              }
            />
            <Step title="Şablon İçeriği Oluştur"
              description={
                activeStep === 1 && templateId ? (
                  <Form
                    form={form2}
                    layout="vertical"
                    onValuesChange={onFormLayoutChange}
                    onFinish={setTemplatesContent}
                    style={{
                      maxWidth: formLayout === 'inline' ? 'none' : 600,
                    }}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Şablon Türü"
                      name="html"
                    >
                      <Select
                        optionFilterProp="children"
                        showSearch
                        onChange={(value) => setSelectTemplateType(value)}
                      >
                        {
                          templateType.map((item: any, index: any) => {
                            return (
                              <Select.Option key={index} value={item.id}>{item.name} </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Şablon Dil Kodu"
                      name="languageCode"
                    >
                      <Input placeholder="tr"  disabled={true}  />
                      {/* <Select
                        optionFilterProp="children"
                        showSearch
                      >
                        <Select.Option key={1} value='tr'>tr </Select.Option>
                        <Select.Option key={2} value='en'>en </Select.Option>
                      </Select> */}
                    </Form.Item>

                    <Form.Item
                      name="subject"
                      label='Şablon Konusu'
                      style={{marginBottom:'20px'}}
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen Şablon konusunu giriniz.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {
                      selectTemplateType === 'ce8ae9da-5b7c-36c3-9f29-29543a9af92d' ? (
                        <HtmlEditor setHtmlContent={setHtmlContent} htmlContent={htmlContent} />
                      ): selectTemplateType === '19841e28-8968-36e7-8765-1aa605a7f5ff' ? (
                        <Form.Item
                          name="subjectSms"
                          label='Sms içerik'
                        >
                          <TextArea showCount maxLength={100}  placeholder="Sms içeriği giriniz." style={{marginBottom:'20px'}}  />
                        </Form.Item>

                        
                      ): ''
                    }
                    

                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading} >
                        İleri
                      </Button>
                    </Form.Item>
                  </Form>) : ('')
              }
            />
            <Step title="Şablon Değişkeni Oluştur"
              description={
                activeStep === 2 && templateId ? (
                  <Form
                    form={form3}
                    layout="vertical"
                    onValuesChange={onFormLayoutChange}
                    onFinish={setTemplatesSave}
                    style={{
                      maxWidth: formLayout === 'inline' ? 'none' : 600,
                    }}
                    autoComplete="off"
                  >
                   <Form.Item
                      label="Şablon Adı "
                      name="templateId"
                    >
                      <Select
                        optionFilterProp="children"
                        showSearch
                      >
                        {
                          templateName.map((item: any, index: any) => {
                            return (
                              <Select.Option key={index} value={item.id}>{item.name} </Select.Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Divider />
                    <Form.Item
                      name={`variableName0`}
                      label='Değişken Adı'>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name={`variableDefinition0`}
                      label='Değişken Tanımı'>
                      <Input />
                    </Form.Item>
                    <Divider />
                    {
                      variable.map((item: any, index: any ) => {
                        return (
                          <>
                            <Form.Item
                              name={`variableName${index+1}`}
                              label='Değişken Adı'>
                              <Input />
                            </Form.Item>

                            <Form.Item
                              name={`variableDefinition${index+1}`}
                              label='Değişken Tanımı'>
                              <Input />
                            </Form.Item>
                            <Divider />
                          </>
                        )
                      })
                    }
                    <Form.Item>
                      <Button type="primary" onClick={showForm} style={{ marginTop: '20px' }} >
                        <PlusOutlined /> Yeni Ekle
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }} >
                        Kaydet
                      </Button>
                    </Form.Item>
                  </Form>
                ) : ('')
              }
            />
          </Steps>
          {/* </Form> */}
        </Col>
      </Row>
    </main>
  );
};

export default TemplateCreate;
