import React, {useState, useEffect } from "react";
import { Row,  Col } from "antd";

import { getUserDetailApi } from "../../services/userAuth";
import { useLocation } from "react-router-dom";

function UserDetail() {
  const location = useLocation();
  const userId = location.pathname.split('/')[2];
  const [userDetail, setUserDetail] = useState<any>();

  const getUserDetail  = async () => {  
    try {
      const response: any = await getUserDetailApi(userId);
      if (response) {
        setUserDetail(response?.user)   
      }
    } catch (error: any) {
      console.log(error);
    }
  }


useEffect(() => {
  getUserDetail();
 // eslint-disable-next-line react-hooks/exhaustive-deps   
}, [])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Kullanıcı Detayı</h3>
        </Col>
      </Row>
      <Row> 
        <Col span={24} style={{paddingRight:'40px', marginTop:'20px'}}>
          <ul className="left-item">
            <li>
              <span>Kullanıcı</span>
              <span>{userDetail?.email}</span>
            </li>
            <li>
              <span>Telefon Numarası:</span>
              <span>{userDetail?.phoneNumber}</span>
            </li>
            <li>
              <span>Kullanıcı Rolü:</span>
              <span>{userDetail?.userRole === 'SUPERVISOR' ? 'ADMİN' : 'KULLANICI'}</span>
            </li>
          </ul>
        </Col>
    
     
      </Row>
    
    </main>
  );
};

export default UserDetail;
