import React,{useEffect} from 'react';
import './App.css';
import AppRouter from './router/appRouter';
import { BrowserRouter } from 'react-router-dom';
import tr_TR from 'antd/es/locale/tr_TR';
import { ConfigProvider } from 'antd';
import { useDispatch } from "react-redux";
import { getProvinces } from "./services/commonApi";
import {setProvincesData} from "./reducers/commonSlice";

function App() {
  const dispatch = useDispatch()

  const getProvince = async () => {
    const response: any = await getProvinces();
    dispatch(setProvincesData(response));
  }
  
  useEffect(() => {
    getProvince();
     // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[]);

  return (
    <>
      <ConfigProvider locale={tr_TR}>
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
}

export default App;
