import React, { useState } from "react";
import { Button, Col, Form, Input, Layout, Row } from 'antd';
import logo from "../assets/logo.png";
import { useDispatch } from "react-redux";
import {setUserData} from "../reducers/userSlice"
import { loginApi } from "../services/userAuth";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { LocalStorageService } from "../helpers/LocalStorage";


const Content = Layout.Content;

const Login = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);

    const onLogin = async (args: any) => {
        try {
            setIsLoading(true);
            const response: any = await loginApi(args);
          
            if (response.token) {           
                setIsLoading(false);
                LocalStorageService.setAuthToken(response?.token); 
                LocalStorageService.setName(response?.email); 
                LocalStorageService.setUserRole(response?.userRole);  
                navigate("/home-page");
            }else{
                setIsLoading(false);
                dispatch(setUserData(response));
                LocalStorageService.setName(response);                              
                navigate("/twoFactor");
                }
            } catch (e: any) {
                setIsLoading(false);  
            }
    }

    return (
        <Layout className="layout">
            <Row className="header-login"> 
                <Col xs={24} md={12} lg={7} style={{textAlign:'center'}}>
                    <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
                </Col>
            </Row>
            <Content className="content">
                <Row justify="center">
                    <Col xs={24} md={12} lg={8} style={{ textAlign:'center'}}>
                        <p className="loginSpan">Depar.io Admin hesabıyla giriş yapın!</p>
                        <Form name="login" onFinish={onLogin} >
                            <Form.Item
                                className="email-input"
                                name="email"
                                rules={[
                                    { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' },
                                    {
                                        required: true,
                                        message: 'Lütfen email giriniz!',
                                    },
                                ]}
                            >
                                <Input placeholder="E-Posta Adresi" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                style={{marginBottom:'20px'}}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifrenizi giriniz!',
                                    },
                                ]}
                            >
                                <Input.Password 
                                    type={"password"} 
                                    placeholder="Şifre"       
                                    iconRender={(visible: any) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                                />
                            </Form.Item>


                            <Form.Item className="m-0">
                                <Button
                                    className="login-btn"
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={isLoading}
                                    block
                                >
                                    Giriş Yap
                                </Button>
                            </Form.Item>

                            {/* <div className="forgotPassword" onClick={()=> navigate("/forgetpassword")}
                            style={{cursor:'pointer'}} >
                                Şifremi Unuttum
                            </div> */}
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default Login; 