/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  email: string;
  navShow: Boolean
}

const initialState = {
  email: '',
  navShow: false
} as UserState;

const userSlice = createSlice({ 
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.email = action.payload;
    },
    setNavShow(state, action) {
      state.navShow = action.payload;
    }
  },
});

export const { setUserData , setNavShow} = userSlice.actions;
export default userSlice.reducer;
