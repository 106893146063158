import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input,notification, Collapse,Typography, InputNumber} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import {convertCurrencyTl} from "../../helpers/common";
import { getSupplierLimitDatas, updatedSupplierLimit, getBuyersListApi} from "../../services/buyerApi";
import { LocalStorageService } from "../../helpers/LocalStorage";


function BuyerUserLimitUpdate() {
  const [form] = Form.useForm();
  const location = useLocation();
  const buyerId = location.pathname.split('/')[2];
  const [supliers, setSupliers] = useState<any>([]);
  const [buyerDefinitions, setBuyerDefinitions] = useState<any>([]); 
  const [bankLimitTotal, setBankLimitTotal] = useState<any>();
  const [bankRiskTotal, setBankRiskTotal] = useState<any>();
  const [supplierCountTotal, setSupplierCountTotal] = useState<any>();
  const [inputValue, setInputValue] = useState<any>();
  const [buyerTitle, setBuyerTitle] = useState<any>([]);


  const { Text } = Typography;
  const Panel = Collapse.Panel;
 
  
  interface IsupplierLimitsUpdate {      
    supplierLimits: Array<IsupplierLimits>;  
    financialInstitutionId : string;
    buyerId : string;   
  }
  interface IsupplierLimits {      
    supplierId: string; 
    limit: number; 
    financialInstitutionId : string;
    buyerId : string;
  }  
  let newData = {} as IsupplierLimitsUpdate;
  newData.supplierLimits = [];
  newData.financialInstitutionId = '';
  newData.buyerId = ''

  const getBuyerList =  async () => {
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        setBuyerTitle(response.buyers.filter((item:any) => item.id === buyerId));
      }
    } catch (error: any) {
      // console.log(error);
    }
  }


  const fetchAllSuppliers  = async () => {
    try {
      const response: any = await getSupplierLimitDatas(buyerId);
      if (response) {
        setSupliers(response.supplierLimits);
        setBuyerDefinitions(response.buyerDefinition); 
      }
    } catch (error: any) {      
      // console.log(error) 
    }
  }

  const updateFinanceLimit = async ( financialId:any, supplierId:any,supplierTaxId:any) => {  

    let newItem = {} as IsupplierLimits;
    newItem.supplierId = supplierId;
    newItem.limit = inputValue;
    newData.supplierLimits.push(newItem);   
    newData.financialInstitutionId = financialId;
    newData.buyerId = buyerId;

    if(inputValue !== '' || inputValue !== undefined){
      try{
        await updatedSupplierLimit(newData);    
        notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
        window.location.reload();         
      }catch(e:any){

        if(e.data.Status === 406 && e.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Tedarikçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
        if(e.data.Status === 406 && e.data.Type.includes('InvalidSupplierLimitException')){
          notification.warning({message: "Tedarikçi Limiti 0' dan küçük olamaz" });
        }        
      }     
    }if(inputValue === undefined){
      notification.success({message: 'Güncellenecek limit girmediniz.'});
    }    
  }

  useEffect(() => {
    getBuyerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId]);
 

  useEffect(() => {
    fetchAllSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBankLimitTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.limit , 0 ))
    setBankRiskTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.risk , 0 ))  
    setSupplierCountTotal(buyerDefinitions.reduce((a:any,v:any) =>  a = a + v.supplierCount, 0 ))
  }, [buyerDefinitions]);

  const header = (supplierTitle:any,supplierTaxId:any, sumRisk:any, sumLimit:any, isRegistered:any) => {
    return (
      <div className="header" style={{display:'flex'}}>
        <div>{supplierTitle}</div>
        <div>{supplierTaxId}</div>
        <div>{convertCurrencyTl(sumRisk)} </div>
        <div>{convertCurrencyTl(sumLimit)}</div>
        <div>{sumRisk === 0 ? '-' : ((sumRisk * 100)/sumLimit)?.toFixed(2)} % </div>
        <div>{
            isRegistered 
            ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
            : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
            }
        </div>
      </div>
    )
  }
  const clearInput = (row:any) =>{
    form.setFieldValue(row.target.id, '');
  }

 
  return (
    <main>
      <Row >
        <Col span={24}>
          <h3 className="page-title"> Alıcı Tedarikçi Limit Güncelleme</h3>
        </Col>
      </Row>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3> Alıcı : {buyerTitle && buyerTitle[0]?.title}</h3>
        </Col>
      </Row>
      <Row>
        <Text style={{fontWeight:'600'}}>Toplam limitinizi tedarikçileriniz arasında bölüştürün.</Text>       
      </Row>
      <Row>
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Banka Risk/Limit Durumu 
          </Text>
      </Row>
      <Row className="limit-header-title">
          <Col span={24}>
              <div className="title buyer-definitions-title">
                  <div>Finans Kurumu</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Tedarikçi <br/> Adedi</div>
              </div>
              <div className="table-li buyer-definitions-li" style={{marginLeft:'0'}}>
                {
                  buyerDefinitions && buyerDefinitions.map((item:any,index:any) => {
                    return (
                      <div key={index}>
                        <div>{item.financialInstitutionName}</div>
                        <div>{convertCurrencyTl(item.risk)} </div>
                        <div>{convertCurrencyTl(item.limit)} </div>
                        <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} %</div>
                        <div>{item.supplierCount}</div>                           
                    </div>
                    )
                  })
                }            
                
                <div>
                  <div>TOPLAM</div>
                  <div>{bankRiskTotal && convertCurrencyTl(bankRiskTotal)} </div>
                  <div>{bankLimitTotal && convertCurrencyTl(bankLimitTotal)} </div>
                  <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                  <div>{supplierCountTotal && supplierCountTotal}</div>
                </div>
              </div>
          </Col>
        </Row>
      <Row style={{marginTop:'30px'}}>        
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
          Tedarikçi Risk/Limit Durumu ve Güncelleme
        </Text>
        <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Tedarikçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Tedarikçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Tedarikçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
      </Row> 
      <Row style={{marginTop:'20px'}}>
          <Col span={10}>
            <Form
               name="filter-box"
              //  onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Tedarikçi Adı veya VKN'sini Seçiniz"
                      style={{minWidth:'450px'}}
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Ara</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
      </Row>
      <div className="limit-header-title-mobil">
        <Row className="limit-header-title">
          <Col span={24}>
              <div className="title">
                  <div>Tedarikçi Adı</div>
                  <div>VKN No</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Platforma <br/> Kayıt Durumu</div>
              </div>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
            <Collapse 
              accordion 
              // defaultActiveKey={['0']} 
              expandIcon={({ isActive }) => (
                isActive ? <MinusCircleOutlined /> :  <PlusCircleOutlined />
              )}
              className="limit-acc-style" >
              {
                supliers && supliers.map((item :any,index:any) => {
                  return(
                    <Panel 
                      className="acc-row"
                      header={header(
                        item.supplierTitle,
                        item.supplierTaxId,
                        item.sumRisk,
                        item.sumLimit,
                        item.isRegistered,
                      )} 
                      key={index}
                    >
                    <div className="table-li">                   
                      {
                        item.risksAndLimits.map((finance:any, i:any) => {
                          return(
                            <div key={i}>
                              <div></div> 
                              <div>{finance.financialInstitutionName}</div>
                              <div>{convertCurrencyTl(finance.risk)} </div>
                              <div>
                                  <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                    <Form.Item 
                                      name={`limit${i}` }
                                      className='hide-arrow'
                                      >
                                      <InputNumber                                        
                                        formatter={(val) => ` ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace(/\.(?=\d{0,2}$)/g, ",")} 
                                        parser={(val:any) => val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")}
                                        defaultValue={`${convertCurrencyTl(finance.limit)}`}
                                        onChange={(val:any) => setInputValue(val)} 
                                        onClick={(row:any) => clearInput(row)}
                                        className="no-spin input-width"
                                        style={{borderRadius:'8px'}}                 
                                        type='text' 
                                        disabled={LocalStorageService.getUserRole() !== 'SUPERVISOR'}
                                      />           
                                    </Form.Item>
                                  <Form.Item>
                                    <Button 
                                      className={`supplier-limit-btn ${LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'} `}
                                      onClick={() => updateFinanceLimit(
                                        finance.financialInstitutionId, 
                                        item.supplierId,
                                        item.supplierTaxId
                                        ) } 
                                    >
                                      Güncelle
                                    </Button>
                                  </Form.Item>
                                </Form>                                
                              </div>
                              <div>{finance.risk === '0' ? '-' : ((finance.risk * 100)/finance.limit)?.toFixed(2)} % </div>  
                              <div></div>                         
                            </div>
                          )
                        })
                      }
                    </div>                  
                    </Panel>
                  )
                })
              }
              </Collapse>
          </Col>       
        </Row>
      </div> 
     
    </main>
  );
};


export default BuyerUserLimitUpdate;
