import React from 'react';
import { Layout, Tabs, Input } from 'antd';


const { TabPane } = Tabs;
const { TextArea } = Input;
const { Content } = Layout;

const HtmlEditor = ({ setHtmlContent, htmlContent }:any) => {
 
  return (
    <Layout className='editor-tab' >
      <Content>
        <Tabs defaultActiveKey="1" animated={{ inkBar: false, tabPane: false }} style={{animation:'false'}}>
          <TabPane tab="HTML" key="1">
            <TextArea
              rows={20}
              value={htmlContent}
              onChange={(e) => setHtmlContent(e.target.value)}
              placeholder="HTML kodunuzu buraya girin..."
            />
          </TabPane>
          <TabPane tab="Görüntüle" key="2">
            <div
              style={{
                border: '1px solid #d9d9d9',
                padding: '10px',
                minHeight: '450px',
                overflow: 'auto'
              }}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default HtmlEditor;