import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Spin, notification } from "antd";
import { useLocation } from "react-router-dom";
import {  getTemplatesNameApi, getTemplatesNameEditApi} from "../../services/managementApi";

const TemplateEditName = () => {
  const [form1] = Form.useForm();
  const location = useLocation();
  const url = location.pathname.split('/');
  const templateId = url[2];
 
  const [formLayout, setFormLayout] = useState('horizontal');
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState<any>([]);

  const getTemplateList = async () => {
    try {
      setLoading(true)
      const response: any = await getTemplatesNameApi();
      if(response){
        setLoading(false);
        setTemplateName(response?.templates?.filter((item:any) => item.id === templateId ));
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

const setTemplatesNameEdit = async (val:any) => {
  setLoading(true);
  const name2 = val?.name !== '' && val.name !== undefined ? val.name : templateName[0]?.name
  try {
      await getTemplatesNameEditApi(templateId, name2);
      setLoading(false);
      notification.success({
        message: `Başarıyla güncellenmiştir.`,
      });
      getTemplateList();
      form1.resetFields();

  } catch (e: any) {
    console.log(e)
    setLoading(false);
  }
}

  useEffect(() => {
    getTemplateList();
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

 
  return (
    <main>
       <Spin spinning={loading} >
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Şablon Adı Düzenle</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            form={form1}
            layout="vertical"
            onValuesChange={onFormLayoutChange}
            onFinish={setTemplatesNameEdit}
            style={{
              maxWidth: formLayout === 'inline' ? 'none' : 600,
            }}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label='Şablon Adı'
            >
              <Input placeholder={templateName && templateName[0]?.name}  />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }}>
                Güncelle
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      </Spin>
    </main>
  );
};

export default TemplateEditName;
