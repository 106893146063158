/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface BuyerState {
  buyerList : any,
  buyerTitle: string
}

const initialState = {
  buyerList: [],
  buyerTitle:''
} as BuyerState;

const buyerSlice = createSlice({ 
  name: 'buyer',
  initialState,
  reducers: {
    setBuyersListData(state, action) {
      state.buyerList = action.payload;
    },
    setBuyerTitle(state, action) {
      state.buyerTitle = action.payload;
    },
  },
});

export const { setBuyersListData, setBuyerTitle } = buyerSlice.actions;
export default buyerSlice.reducer;
