import React, { useState } from "react";
import { Row, Col, Form, Button, Spin, notification, Upload } from "antd";
import { DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import {  getTemplateExportApi, getTemplateImportApi } from "../../services/managementApi";

const DataTransfer = () => {
  const [form] = Form.useForm();
 
  const [loading, setLoading] = useState(false);

  const beforeUpload = () => {
    // Prevent upload
    return false
  }
  
  const handleChange = async (info:any) => {
    setLoading(true)
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    const formData = new FormData();
    formData.append('file',newFileList[0]?.originFileObj)
    if(newFileList.length > 0 && newFileList !== undefined){
      try{
        await getTemplateImportApi( formData);
        setLoading(false)
      } catch(e:any){
        console.log(e);
        setLoading(false)
      } 
    }else{
      notification.warning({
        message: `Dosya seçmediniz.`,
    }); 
    }
  };

  const handleTemlikDownload = async () => {
    try{
      setLoading(true)
      const response:any = await getTemplateExportApi();
      if (response) {
       setLoading(false)
    }
    } catch (e:any) {
      console.error(e);

    } finally {
      setLoading(false);
    }
};
 
  return (
    <main>
       <Spin spinning={loading} >
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Veri Aktarma</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Form
              form={form}
              name="user-update"
              className="default-form"
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 800,
              }}
          >
            <Form.Item label='Dosyayı indirmek için tıklayınız.'>
              <Button   icon={<DownloadOutlined />} style={{minWidth:'135px'}} onClick={handleTemlikDownload}> Dosya İndir</Button>
            </Form.Item>

            <Form.Item label='Yüklemek istediğiniz dosyayı seçiniz.'> 
              <Upload   beforeUpload={beforeUpload} onChange={handleChange}   maxCount={1}>
                <Button icon={<UploadOutlined />} style={{minWidth:'135px'}}>Dosya Yükle</Button>
              </Upload>
            </Form.Item>
       
          </Form>
        </Col>
      </Row>
      </Spin>
    </main>
  );
};

export default DataTransfer;
