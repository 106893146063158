import React, { useState} from "react";
import { Row, Col,Button, Form, Input, Select,  notification} from "antd";
import { financialInstitutionSaveApi} from "../../services/financeApi";
import { useSelector} from 'react-redux';
import {validateVkn,validateEmail} from "../../helpers/validators";
import {getTaxAdministartions, getDistrictsApi} from "../../services/commonApi"

const CorporationSave = () => { 
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const {provinces} = useSelector((state: any) => state.common);


  const onFinish =  async (values:any) => {
    const provinceName = provinces.filter((item:any) => {
      if(item.provinceId ===  values.invoiceProvince){
        return item
      } 
      return 
    } )
    const userData : any = {
      name : values.title,
      taxId : values.taxId,
      accountingEmail : values.accountingEmail,
      invoiceProvince:  provinceName[0].name,
      invoiceDistrict : values.invoiceDistrict,
      taxAdministration : values.taxAdministration,
      invoicePostCode : values.invoicePostCode,
      commissionRate : parseFloat(values.commissionRate) ,
      invoiceAddress : values.invoiceAddress,
    };
 
    try {
      const response: any = await financialInstitutionSaveApi(userData);
        if (response) {
          notification.success({
            message: `Başarıyla güncellenmiştir.`,
        });   
       form.resetFields();
        }else{
          notification.warning({
            message: `Bilgilerinizi kontrol ederek yeniden deneyiniz.`,
        });   
        }
      } catch (error: any) {
        console.log(error);
        if(error.data.Status === 409 && error.data.Type.includes('FinancialInstitutionWithSameTaxIdExistException')){
          notification.error({message: 'Aynı Vergi Numarasına Sahip Finansal Kuruluşu Mevcuttur.'});
        }
      }
  };


const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['invoiceDistrict']);
 
  try {
    const response: any = await getDistrictsApi(dataId);
    if (response) {
      setDistrictList(response);
    }
  } catch (error: any) {
    console.log(error);
  }

  try {
    const response: any = await getTaxAdministartions(dataId);
    if (response) {
      setTaxAdministartion(response);
    }
  } catch (error: any) {
    console.log(error);
  }
}


  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Finans Kurumu Hesap Açılış İşlemleri</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Form
          form={form}
          name="user-update"
          className="default-form"
          onFinish={onFinish}
          labelCol={{
          span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          initialValues={{
            size: 'large',
          }}
          size={'large'}
          style={{
            width: '100%',
            display:'flex'
          }}
          >
       
          <div className="form-col-box" style={{paddingTop:'43px'}}>

            <Form.Item 
              label="Ünvan:"
              name="title"
              rules={[
                {
                    required: true,
                    message: 'Lütfen ünvan giriniz.',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Vergi Kimlik Numarası:" 
              name="taxId"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateVkn(value)
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Muhasebe E-mail Adresi:"
              name="accountingEmail"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateEmail(value)
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="İl:"
              name="invoiceProvince"
              rules={[
                {
                    required: true,
                    message: 'Lütfen il giriniz.',
                },
              ]}
              >
              <Select 
                onChange={(e) => getDistrictsTaxId(e)}
                optionFilterProp="children"
                showSearch
                >
                {
                 provinces && provinces.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.provinceId}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
           
        
            </div>
            <div className="form-col-box">
              <h4 style={{marginBottom:'20px', textDecoration:'underline'}}>Fatura Adresi</h4>
              <Form.Item 
              label="Vergi Dairesi:"
              name="taxAdministration"
              rules={[
                {
                    required: true,
                    message: 'Lütfen vergi dairesi seçiniz.',
                },
              ]}
              >
              <Select   
                optionFilterProp="children"
                showSearch
                >
                {
                 taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  }): ''
                }
               
              </Select>
            </Form.Item>
         
            <Form.Item 
              label="İlçe:"
              name="invoiceDistrict"
              rules={[
                {
                    required: true,
                    message: 'Lütfen ilçe giriniz.',
                },
              ]}
              >
              <Select    
                optionFilterProp="children"
                showSearch
                >
                {
                 districtList && districtList.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item}>{item}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <Form.Item 
              label="Posta Kodu:"
              name="invoicePostCode"
              rules={[
                {
                    required: true,
                    message: 'Lütfen posta kodu giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Komisyon Oranı:"
              name="commissionRate"
              rules={[
                {
                    required: true,
                    message: 'Lütfen komisyon oranı giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Adres"
              name="invoiceAddress"
              rules={[
                {
                    required: true,
                    message: 'Lütfen adres giriniz.',
                },
              ]}
            >
              <TextArea
               rows={4} 
               />
            </Form.Item>
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Kaydet</Button>
            </Form.Item>
      
            </div>
        </Form>
        </Col>
      </Row>
    </main>
  );
};

export default CorporationSave;
