import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, notification} from "antd";
import { useLocation } from "react-router-dom";
import { getFinanceUserInfoApi, getCorporationsListApi, updateFinanceUserApi} from "../../services/financeApi";
import { useSelector,useDispatch} from 'react-redux';
import {setFinancialInstitutionsData} from "../../reducers/financeSlice";
import {validateGsmNumber,validateEmail} from "../../helpers/validators"


const CorporationUserUpdate = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const financeUserId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [financialUserInfo, setFinancialUserInfo] = useState<any>();
  const {financialInstitutions} = useSelector((state: any) => state.finance);

  const onFinish =  async (values:any) => {
    const userData : any = {
      name : values.name ? values.name : financialUserInfo?.name,
      surname : values.surname ? values.surname : financialUserInfo?.surname,
      phoneNumber : values.gsmNumber ? values.gsmNumber : financialUserInfo?.gsmNumber,
      userRole: values.financialInstitutionRole ? values.financialInstitutionRole :  financialUserInfo?.userRole
    }
    try {
        await updateFinanceUserApi(financeUserId, userData);
        notification.success({
            message: `Başarıyla güncellenmiştir.`,
        }); 
        getFinanceUserInfo(financeUserId);
        window.location.reload();
        
      } catch (error: any) {
        console.log(error);
      }
  };


const getFinanceUserInfo =  async (financeUserId:any) => {
  try {
    const response: any = await getFinanceUserInfoApi(financeUserId);
    if (response) {
      setFinancialUserInfo(response.financialInstitutionUser);
    }
    } catch (error: any) {
      console.log(error);
    }
}

const getCorporationsList =  async () => {
  try {
    const response: any = await getCorporationsListApi();
    if (response) {
      dispatch(setFinancialInstitutionsData(response.financialInstitutions));
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
    if(financeUserId){
      getFinanceUserInfo(financeUserId);
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }
  },[financeUserId])

  useEffect(() => {
      getCorporationsList();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[])

 

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Finans Kullanıcı Güncelleme</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
            <Form.Item 
              label="Adı:"
              name="name"
              >
              <Input placeholder={financialUserInfo?.name} />
            </Form.Item>
            <Form.Item 
              label="Soyadı:"
              name="surname"
              >
              <Input placeholder={financialUserInfo?.surname} />
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="gsmNumber"
              rules={[
                {
                  validator: (_, value) => validateGsmNumber(value ? value : financialUserInfo?.gsmNumber)
                },
              ]}
            >
              <Input placeholder={financialUserInfo?.gsmNumber} />
            </Form.Item>
            <Form.Item 
              label="E-posta Adresi:"
              name="email"
              rules={[
                {
                  validator: (_, value) => validateEmail(value ? value : financialUserInfo?.email)
                },
              ]}
              >
              <Input placeholder={financialUserInfo?.email} disabled />
            </Form.Item>
            <Form.Item 
              label="Finansal Kurum:"
              name="financialInstitutionName"
              >
              <Select placeholder={financialUserInfo?.financialInstitutionName} disabled >
                {
                 financialInstitutions && financialInstitutions.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <Form.Item
                  label="Kullanıcı Rolü"
                  name="financialInstitutionRole"
                  rules={[
                      {
                          message: 'Lütfen finans kurumu rolü seçiniz.',
                      },
                  ]}
              >
                  <Select placeholder={financialUserInfo?.userRole === 'API_USER' ? 'API Kullanıcısı': 'UI Kullanıcısı'}  >
                      <Select.Option key="1" value="API_USER">API Kullanıcısı</Select.Option>
                      <Select.Option key="2" value="UI_USER">UI Kullanıcısı</Select.Option>
                  </Select>
              </Form.Item>
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Güncelle</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default CorporationUserUpdate;
