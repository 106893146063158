import React, {useEffect, useState} from "react";
import { Row, Col, Table, Button, Modal, notification} from "antd";
import {  useLocation ,useNavigate} from "react-router-dom";
import { getCorporationsUserApi, deleteFinanceUser, forgotPasswordApi } from "../../services/financeApi";
import { LocalStorageService } from "../../helpers/LocalStorage";

const CorporationUsersList = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const financeId = location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [financialUsersList, setFinancialUsersList] = useState<any>();
  const [delfinanceUser, setDelFinanceUser] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);

  const columns : any = [
    {
      title: 'Adı',
      dataIndex: 'name',
      key: "name",
      fixed: 'left',
    },
    {
      title: 'Soyadı',
      dataIndex: 'surname',
      key: "surname",
    },
    {
      title: 'E-posta Adresi',
      dataIndex: 'email',
      key: "email",
    },
    {
      title: 'Cep Telefonu',
      dataIndex: 'gsmNumber',
      key: "gsmNumber",
    },
    {
      title: 'Kullanıcı Rolü',
      dataIndex: 'userRole',
      key: "userRole",
      render: (text: any, row: any) =>  text === 'UI_USER' ? 'UI Kullanıcısı' : 'API Kullanıcısı' 
    },
    {
      title:'',
      dataIndex:'update',
      key:'update', 
      width:120,    
      render: (text: any, row: any) => 
        <Button 
          type="primary"
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={()  => navigate(`/corporations-user-update/${row?.id}`)}
        > 
          Güncelle
        </Button>           
      },
    {
      title:'',
      dataIndex:'sil',
      key:'sil', 
      width:120,     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={ () =>  showModal(row.id) }
          > Sil
        </Button>         
    },
    {
      title:'',
      dataIndex:'passwordChange',
      key:'passwordChange',     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={ () =>  forgotPassword(row) }
          > Şifre Sıfırla
        </Button>         
    },
  ];
 
  const forgotPassword = async (row:any) => {
    const data = {
      email: row?.email,
      name:row?.name,
      surname:row?.surname
    }
    try{
        await forgotPasswordApi(data);
        notification.success({
        message: `Lütfen mailinizi kontrol ediniz.`,
      });
    }catch(e:any){
      console.log(e);
    }
        
  }

  const getCorporationsUserList = async () => {
    try {
      setLoading(true);
      const response: any = await getCorporationsUserApi(financeId);
      if (response) {
        setLoading(false);
        setFinancialUsersList(response.financialInstitutionUserList);
        setTotalDataCount(10);
      }
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
  }

  const deletefinanceUser =  async () => {
    if(delfinanceUser){
      try {
         await deleteFinanceUser(delfinanceUser); 
         getCorporationsUserList();
        } catch (error: any) {
          console.log(error);
        }
    }
}

  const showModal = (id:any) => {
    setOpen(true);
    setDelFinanceUser(id)
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    deletefinanceUser();
  };
  const handleCancel = () => {
    setOpen(false);
  };
  
  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  useEffect(() => {
    getCorporationsUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])


  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Kurum Kullanıcı Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={financialUsersList}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};

export default CorporationUsersList;
