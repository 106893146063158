import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, notification} from "antd";
import {  getBuyersListApi, buyerUserSaveApi} from "../../services/buyerApi";
import { useSelector,useDispatch} from 'react-redux';
import {setBuyersListData} from "../../reducers/buyerSlice"
import {validateGsmNumber,validateEmail} from "../../helpers/validators"



const UserIdentificationSave = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const {buyerList} = useSelector((state: any) => state.buyer);

  const onFinish =  async (values:any) => {
    setLoading(true);
    const userData : any = {
      email : values.email,
      nameSurname : values.nameSurname,
      phoneNumber : values.phoneNumber,
      title : values.title, 
      buyerId: values.buyerId
    };
      try {
        const response: any = await buyerUserSaveApi(userData);
          if (response) {
            notification.success({
              message: `Başarıyla yeni kayıt oluşturuldu.`,
          }); 
          form.resetFields();
          setLoading(false)
          }else{
            notification.warning({
              message: `Bilgilerinizi kontrol ederek yeniden deneyiniz.`,
          }); 
          }
        } catch (error: any) {
          console.log(error);
          setLoading(false);
          if(error.status === 409){
            notification.error({
              message: `Email adresi daha önce kullanılmış.`,
          }); 
          }
        }
  };


const getBuyersList =  async () => {
  try {
    const response: any = await getBuyersListApi();
    if (response) {
      dispatch(setBuyersListData(response.buyers));
    }
  } catch (error: any) {
    console.log(error);
  }
}


  useEffect(() => {
      getBuyersList();
       // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[])


  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Alıcı Kullanıcısı Tanımlama</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
            <Form.Item 
              label="Alıcı Seçiniz:"
              name="buyerId"
              rules={[
                {
                    required: true,
                    message: 'Lütfen finans kurumu seçiniz.',
                },
              ]}
              >
              <Select>
                {
                 buyerList && buyerList.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.id}>{item.title}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <h3 style={{padding:'20px 0', textDecoration:'underline'}}>Kişisel Bilgiler</h3>
            <Form.Item 
              label="Ad / Soyad:"
              name="nameSurname"
              rules={[
                {
                    required: true,
                    message: 'Lütfen ad soyad giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Görev / Ünvan:"
              name="title"
              rules={[
                {
                    required: true,
                    message: 'Lütfen soyad giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="E-posta Adresi:"
              name="email"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateEmail(value)
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateGsmNumber(value)
                },
              ]}
            >
              <Input/>
            </Form.Item>
            
            
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit" loading={loading}>Kullanıcı Oluştur</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default UserIdentificationSave;
