import React, { useState} from "react";
import { Row, Col,Button, Form, Input, Select, notification} from "antd";
import { userSaveApi } from "../../services/userAuth";
import {validateGsmNumber,validateEmail, validatePassword} from "../../helpers/validators"

const NewUser = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [userRole, setUserRole] = useState<any>();

  const onFinish =  async (values:any) => {
    setLoading(true);
    const userData : any = {
        email : values.email,
        password : values.password,
        phoneNumber : values.phoneNumber,
        userRole: userRole
    };
      try {
         await userSaveApi(userData);
         setLoading(false);
         notification.success({
           message: `Başarıyla yeni kayıt oluşturuldu.`,
       }); 
       form.resetFields();
         
        } catch (e: any) {
          setLoading(false);
         
      }
  };

const getUserRole = (userRole:any) => {
    setUserRole(userRole)
}

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Kullanıcı Oluştur</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
              <Form.Item 
              label="Email Adresi:"
              name="email"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateEmail(value)
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateGsmNumber(value)
                },
              ]}
            >
              <Input/>
            </Form.Item>

            <Form.Item 
                label="Şifreniz:"
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password  style={{marginTop:'0px', width:'100%'}}  />
              </Form.Item>
          
              <Form.Item
                  label="Kullanıcı Rolü"
                  name="userRole"
                  rules={[
                      {
                          required: true,
                          message: 'Lütfen kullanıcı rolü seçiniz.',
                      },
                  ]}
              >
                  <Select onChange={getUserRole}
                  >
                      <Select.Option key="1" value="SUPERVISOR">ADMİN</Select.Option>
                      <Select.Option key="2" value="REPRESENTATIVE">KULLANICI</Select.Option>
                  </Select>
              </Form.Item>
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit" loading={loading}>Kaydet</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default NewUser;
