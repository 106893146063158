import React, { useState, useEffect, useRef } from "react";
import { Row,Col, Table, Button, Modal, notification, Form, Select,DatePicker, Typography, Input,Space } from "antd";
import moment from 'moment';
import { getBuyersListApi, getInvoicesUnusetApi,updateTermInvoiceApi} from "../../services/buyerApi";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import {uniq as _uniq , map as _map} from "lodash";
import { convertFloatDotSeperated } from "../../helpers/common";
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';

function InvoiceMaturityUpdate() {

  const { Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<React.Key[]>([]);
  const [invoicesUnused, setInvoicesUnused] = useState<any>();
  const [ownersList, setOwnersList] = useState<any>();
  const [ownersListTaxNumber, setOwnersListTaxNumber] = useState<any>();
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState<any>();
  const [supplierCount, setSupplierCount] = useState<any>(0);
  const [buyerId, setBuyerId] = useState<any>('');
  const [filters, setFilters] = useState<any>({
    invoiceDateBegin: '',
    invoiceDateEnd: '',
    invoiceOwnerTitle: '',
    invoiceOwnerTaxNumber: '',
    invoiceNumber:'',
    invoiceTermBegin: '',
    invoiceTermEnd: '',
  });
  const [buyerList, setBuyerList] = useState<any>([]);
  const [invoiceTerm, setInvoiceTerm] = useState<string>();
  const [textInvoiceTerm, setTextInvoiceTerm] = useState<string>();
  const [data, setData] = useState<any>();

  const handleInput = async (buyerId:any) => {
    setBuyerId(buyerId)
    if(buyerId){
      getAllInvoices(buyerId);
    } 
  }

  const getBuyerList =  async () => {
    setLoading(true)
    try {
      const response: any = await getBuyersListApi();
      if (response) {
        setBuyerList(response.buyers);
        setLoading(false)
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false)
    }
  }
  
  const getAllInvoices  = async (buyerId:any) => {  
    try {
      setLoading(true);
      const {
        invoiceDateBegin,
        invoiceDateEnd,
        invoiceOwnerTitle,
        invoiceOwnerTaxNumber,
        invoiceNumber,
        invoiceTermBegin,
        invoiceTermEnd
      } = filters;     
      const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
        ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&` : ''}
        ${invoiceOwnerTitle ? `invoiceOwnerTitle=${invoiceOwnerTitle}&` : ''}
        ${invoiceOwnerTaxNumber ? `invoiceOwnerTaxNumber=${invoiceOwnerTaxNumber}&` : ''}
        ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}`;
        const response: any = await getInvoicesUnusetApi(filterStr.trim().replaceAll(/\n/g,''), pageSize, offset,buyerId);
    
      if (response) {
        setLoading(false);
        setInvoicesUnused(response.invoiceRecords.data);
        setTotalInvoices(response.invoiceRecords.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error)
    }
  }

  const checkDate = (checkDate: any, date1: any, date2:any) => {
    return moment(moment(checkDate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

  const handleDateSearch = (confirm: any) => {
    getAllInvoices(buyerId)
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceDateBegin: '',
      invoiceDateEnd: '',
      invoiceOwnerTitle: '',
      invoiceOwnerTaxNumber: '',
      invoiceNumber:'',
      invoiceTermBegin: '',
      invoiceTermEnd: '',
    })
  };

  const getColumnTimeProps = (dataIndex: string) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
        <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</Text>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
              invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
              invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
              invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
              invoiceNumber: filters.invoiceNumber,
              invoiceOwnerTitle: filters.invoiceOwnerTitle,
              invoiceOwnerTaxNumber:filters.invoiceOwnerTaxNumber
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
          <Button
            type="primary"
            role="search"
            onClick={() => {
              handleDateSearch(confirm)
            }}
            style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
            icon={<SearchOutlined />}
            size="small"
          >
            Ara
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  interface DataType {
    key: string;
    invoiceNumber: string;
  }
  
  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      invoiceNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceNumber: '',
    })
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN/TCKN'
          // value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleChange = (pagination: any, _filters: any, key:any) => {
    setOffset(pagination.current);
    setPageSize(pagination.pageSize);
    setFilters({
      ...filters,
      invoiceOwnerTitle: _filters?.invoiceOwnerTitle !== null ? _filters?.invoiceOwnerTitle.map((item:any) => item) : '',
      invoiceOwnerTaxNumber: _filters?.invoiceOwnerTaxNumber !== null ? _filters?.invoiceOwnerTaxNumber.map((item:any) => item) : '',
      invoiceNumber: _filters?.invoiceNumber !== null ? _filters?.invoiceNumber.map((item:any) => item) : filters.invoiceNumber,
    })
    setFilteredInfo(_filters);
  };

  const dateChange = (date:any) => {
    setTextInvoiceTerm(new Date(date).toLocaleDateString('tr-TR'))
    setInvoiceTerm(new Date(date).toISOString())
  };

  const  disabledDate = (current: any) => {  
    return (
      (new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6)
    );
  }

  const updateTermInvoice = async () => {
    try{
      await updateTermInvoiceApi({"invoiceIds":selectedRowKeys, invoiceTerm: invoiceTerm}, buyerId)
    getAllInvoices(buyerId);
   
    }catch(e:any){
      if(e.response.data.Status === 400 && e.response.data.Type.includes('InvalidInvoiceTermException')) {
        notification.warning({
          message: `Fatura ödeme vadesi iş günü olmalıdır.`,
      });
      }
    }
   
  }

  const showModal = () => {
   if(selectedRowKeys.length > 0){
    setOpen(true);
   }
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
    updateTermInvoice();
   data !== undefined && setSelectedRowKeys([]);
    setSupplierCount(0)
  };
  const handleCancel = () => {
    setOpen(false);
  };


  const rowSelection = {
    onChange:  (selectedRowKeys:any, selectedRows:any) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRow(selectedRows);
  
      const count = selectedRows.reduce(function(prev:any, cur:any) {
        prev[cur.invoiceOwnerTaxNumber] = (prev[cur.invoiceOwnerTaxNumber] || 0) + 1;
        return prev;
      }, {});
      setSupplierCount(count)
    },
  };

  const handleShowSize = (current: any, size: any) => {
    setOffset(current);
    setPageSize(size);
  }

  useEffect(() => {
    if(invoicesUnused && invoicesUnused.length > 0) {
      const invOwnerList = _uniq(_map(invoicesUnused, 'invoiceOwnerTitle'))
      if (invOwnerList && invOwnerList.length > 0) {
        setOwnersList(invOwnerList.reduce((newData: any, owner: any) => {
          newData.push({text: owner, value: owner})
          return newData;
        }, [])) 
      }
      const invoiceOwnerTaxNumberList = _uniq(_map(invoicesUnused, 'invoiceOwnerTaxNumber'))
      if (invoiceOwnerTaxNumberList && invoiceOwnerTaxNumberList.length > 0) {
        setOwnersListTaxNumber(invoiceOwnerTaxNumberList.reduce((newData: any, owner: any) => {
          newData.push({text: owner, value: owner})
          return newData;
        }, [])) 
      }
    }
  }, [invoicesUnused])

  useEffect(() => {
    getBuyerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [])

  useEffect(() => {
    if(buyerId){
      getAllInvoices(buyerId);
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    }
   
  }, [offset, pageSize, filters])

  const columns: any = [
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: 'left',
      width:180,
      ...getColumnSearchProps('invoiceNumber'),
    },
    {
      title: "Tedarikçi Ünvanı",
      dataIndex: "invoiceOwnerTitle",
      key: "invoiceOwnerTitle",
      filterSearch: true,
      filters: ownersList || [],
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTitle) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTitle.includes(value),
      ellipsis: true,
    },
    {
      title: "Tedarikçi VKN",
      dataIndex: "invoiceOwnerTaxNumber",
      key: "invoiceOwnerTaxNumber",
      filters: ownersListTaxNumber || [],
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTaxNumber) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTaxNumber.includes(value),
      ellipsis: true,
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      ...getColumnTimeProps('invoiceDate'),
    },
    {
      title: "Fatura Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      ...getColumnTimeProps('invoiceTerm'),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
    },
  ];


  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Fatura Vadesi Güncelleme</h3>
          <p>
          Bu sayfada daha önce Alıcıların yüklemiş olduğu ve finansman konusu olmamış faturaların ödeme vadelerini güncelleyebilirsiniz
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{marginBottom:'20px'}}>
        <Form
              form={form}
              name="user-update"
              className="default-form"
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
            <Form.Item 
              label="Alıcılar:"
              name="buyerId"
              rules={[
                {
                    required: true,
                    message: 'Lütfen alıcı seçiniz.',
                },
              ]}
              >
              <Select onChange={(e:any) => handleInput(e)}  placeholder="Alıcı Seçiniz" loading={loading}>
                {
                 buyerList && buyerList.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.id} >{item.title}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Row >
        <Col span={24} style={{ margin: "0px auto" }}>
          {
            invoicesUnused ? (
              <Table
              className="w-100 invoiceTable"
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
                }}
              rowKey="id"
              pagination={{
                position: ["topRight", "none" as any],
                showLessItems: true,
                showSizeChanger: true,
                pageSize,
                onShowSizeChange: handleShowSize,
                total: totalInvoices && totalInvoices,
              }}
              loading={loading}
              onChange={handleChange}
              dataSource={invoicesUnused}
              columns={columns}
              scroll={{
                x: 1200,
              }}
            />
            ):
            (<p>Faturaları görüntülemek için alıcı seçiniz.</p>)
          }
         
        </Col>
      </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ margin: "0px auto" }}>
          <div className="delete-invoices-bottom" style={{justifyContent:'flex-end', alignItems:'end', flexDirection:'column'}}>
            <div style={{display:'flex', alignItems:'center', gap:'15px'}}>
              <p style={{marginBottom:'0'}}>{supplierCount && Object.keys(supplierCount).length} Tedarikçide toplam {selectedRowKeys.length} fatura seçtiniz. <strong>Güncellemek istediğiniz yeni ödeme vadesi:  </strong></p>
               <DatePicker 
                onChange={dateChange} 
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
                />
            </div>
            <div>
              <Button type="primary" 
                onClick={showModal} 
                disabled={selectedRowKeys.length>0 && invoiceTerm ? false : true}
                >Fatura Vadelerini Güncelle</Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        className="invoices-unused-modal"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};


export default InvoiceMaturityUpdate;
