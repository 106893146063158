import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Spin, notification, Select, Divider } from "antd";
import { getTemplatesNameApi, getTemplateVariableApi, sendTestMail } from "../../services/managementApi";
import { validateEmail } from "../../helpers/validators";

const TestMailSend = () => {
  const [form1] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState<any>([]);
  const [formVariable, setFormVariable] = useState<any>([]);

  const getTemplateList = async () => {
    try {
      setLoading(true);
      const response: any = await getTemplatesNameApi();
      if (response) {
        setLoading(false);
        setTemplateName(response.templates);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const setTemplatesSend = async (values: any) => {
    setLoading(true);
    let newData: any = [];

    const variables = formVariable.reduce((acc: any, item: any, index: any) => {
      const key = item.name.replace(/[{}@]/g, ''); 
      const value = values[`variableDefinition${index + 1}`];
      acc[key] = value;
      return acc;
    }, {});

    newData = { variables, templateId: values.templateId, email: values.email };

    try {
      await sendTestMail(newData);
      notification.success({
        message: `Başarıyla gönderildi.`,
      });
      form1.resetFields();
      setFormVariable([]);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleSelectChange = async (val: any) => {
    setFormVariable([]);
    try {
      setLoading(true);
      const response: any = await getTemplateVariableApi(val);
      if (response) {
        setFormVariable(response);
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTemplateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Spin spinning={loading}>
        <Row className="margin-bottom">
          <Col span={24}>
            <h3 className="page-title">Test Maili Gönderme</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              form={form1}
              layout="vertical"
              onValuesChange={onFormLayoutChange}
              onFinish={setTemplatesSend}
              style={{
                maxWidth: formLayout === 'inline' ? 'none' : 600,
              }}
              autoComplete="off"
            >
              <Form.Item
                name="templateId"
                label="Şablon seçiniz"
                style={{ marginBottom: '20px' }}
              >
                <Select
                  optionFilterProp="children"
                  showSearch
                  onChange={handleSelectChange}
                >
                  {
                    templateName.map((item: any, index: any) => (
                      <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="email"
                label='Email adresiniz'
                style={{ marginBottom: '20px' }}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validateEmail(value)
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Divider />

              {formVariable.length > 0 && formVariable.map((item: any, index: any) => (
                <React.Fragment key={index}>
                  <Form.Item
                    name={`variableName${index + 1}`}
                    label="Değişken Adı"
                    style={{ marginBottom: '20px' }}
                  >
                    <Input  placeholder={item.name.replace(/[{}@]/g, '')} disabled={true}/>
                  </Form.Item>

                  <Form.Item
                    name={`variableDefinition${index + 1}`}
                    label="Değişken Tanımı"
                    style={{ marginBottom: '20px' }}
                  >
                    <Input />
                  </Form.Item>
                  <Divider />
                </React.Fragment>
              ))}

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }}>
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </main>
  );
};

export default TestMailSend;
