/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface CommonState {
  provinces : any
}

const initialState = {
  provinces: []
} as CommonState;

const commonSlice = createSlice({ 
  name: 'common',
  initialState,
  reducers: {
    setProvincesData(state, action) {
      state.provinces = action.payload;
    },
  },
});

export const { setProvincesData } = commonSlice.actions;
export default commonSlice.reducer;
