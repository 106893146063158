import React, {useEffect, useState} from "react";
import { Row, Col, Table, Button, Modal,notification} from "antd";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { getBuyerUsersApi , deleteBuyerUserApi, forgotPasswordApi} from "../../services/buyerApi";
import { LocalStorageService } from "../../helpers/LocalStorage";

const CorporationUsersList = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const buyerId = location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buyerUsersList, setBuyerUsersList] = useState<any>();
  const [delBuyerUser, setDelBuyerUser] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [buyerTitle, setBuyerTitle] = useState<any>();

  const columns : any = [
    {
      title: 'Adı Soyadı',
      dataIndex: 'nameSurname',
      key: "nameSurname",
      fixed: 'left',
    },
    {
      title: 'Görev / Ünvan',
      dataIndex: 'title',
      key: "title",
    },
    {
      title: 'E-posta Adresi',
      dataIndex: 'email',
      key: "email",
    },
    {
      title: 'Cep Telefonu',
      dataIndex: 'phoneNumber',
      key: "phoneNumber",
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdDate',
      key: "createdDate",
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title:'',
      dataIndex:'update',
      key:'update', 
      width:120,    
      render: (text: any, row: any) => 
        <Button 
          type="primary"
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={()  => navigate(`/buyer-user-update/${row?.id}`)}
        > 
          Güncelle
        </Button>           
      },
    {
      title:'',
      dataIndex:'sil',
      key:'sil',   
      align: 'center',  
      width:120, 
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          className={LocalStorageService.getUserRole() === 'SUPERVISOR' ? 'useShow' : 'useHide'}
          onClick={ () =>  showModal(row.id) }
          > Sil
        </Button>         
    },
    {
      title:'',
      dataIndex:'passwordChange',
      key:'passwordChange',     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={ () =>  forgotPassword(row) }
          > Şifre Sıfırla
        </Button>         
    },
  ];

  const forgotPassword = async (row:any) => {
    const data = {
      email: row?.email,
      nameSurname:row?.nameSurname,
    }
    try{
        await forgotPasswordApi(data);
        notification.success({
        message: `Lütfen mailinizi kontrol ediniz.`,
      });
    }catch(e:any){
      console.log(e);
    }
        
  }
  
  const deleteBuyerUser =  async () => {
      if(delBuyerUser){
        try {
          await deleteBuyerUserApi(delBuyerUser);
            getBuyerUsersList();
          
          } catch (error: any) {
            console.log(error);
          }
      }
  }

  const getBuyerUsersList = async () => {
    try {
      setLoading(true);
      const response: any = await getBuyerUsersApi(buyerId);
      if (response) {
        setLoading(false);
        setBuyerUsersList(response.buyerUsers);
        setBuyerTitle(response.buyerTitle);
        setTotalDataCount(10);
      }
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
  }

  const showModal = (id:any) => {
    setOpen(true);
    setDelBuyerUser(id)
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
     deleteBuyerUser();
  };
  const handleCancel = () => {
    setOpen(false);
  };
  
  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  useEffect(() => {
    getBuyerUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])


  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Alıcılar Kullanıcılar Listesi</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h3> Alıcı : {buyerTitle && buyerTitle}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
        <Table
           className="w-100 invoiceTable"
           pagination={{
             position: ["topRight", "none" as any],
             showLessItems: true,                          
             showSizeChanger: true,
             current: page,
             pageSize:psize,
             total:totalDataCount, 
             onChange: handleShowSize,
             
           }}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={buyerUsersList}
          scroll={{
            x: 1200,
          }}
          
        />
        </Col>
      </Row>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
      >
        <p>İşlemi yapmak istediğinizden emin misiniz?</p>
      </Modal>
    </main>
  );
};

export default CorporationUsersList;
