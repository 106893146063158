import {notification} from 'antd';
import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const loginApi = async (payload:any) => {
  try {
    const { email, password } = payload;
      const data = await api.post(`Authentication/login`, { email, password });
      if (data.token) {
          LocalStorageService.setAuthToken(data.token);
      }
      return data;
  } catch (e:any) {
      if(e.status === 401 && e.data.Type.includes('InvalidPasswordException')){
        notification.warning({
            message: `Kullanıcı adınız ya da şifreniz hatalıdır.`,
        });
    }
    if(e.status === 404 && e.data.Type.includes('UserNotFoundException')){
      notification.warning({
          message: `Kullanıcı adınız ya da şifreniz hatalıdır.`,
      });
    }
    if(e.status === 401 && e.data.Type.includes('UserHasLockedException')){
      notification.warning({
          message: `Birden fazla başarısız giriş denemesi gerçekleştirdiniz. Lütfen 1 dakika sonra tekrar deneyiniz.`,
      });
  }
   
  }
};

const twoFactor = async (data: any): Promise<Object> => {
  return (
    await api.post(`otp/validate`, data)
  );
};

const getUsersDataApi = async (): Promise<Object> => {
  return await api.get(`User/users`,
    {}
  );
};

const getUserDetailApi = async (userId: any): Promise<Object> => {
  return await api.get(`User/user/${userId}`,
    {}
  );
};

const updateUserApi = async (userId:string, newData: any): Promise<Object> => {
  return (
    await api.patch(`User/${userId}`,newData , {})
  );
};

const userSaveApi = async (newData: any): Promise<Object> => {
  return (
    await api.post(`User/user`,newData , {})
  );
};

const deleteUserApi = async (userId: any): Promise<Object> => {
  return await api.delete(`User/${userId}`,
    {}
  );
};

const forgotPasswordApi = async (data: any): Promise<Object> => {
  return (
    await api.post(`User/forgot-password`, data)
  );
};


const resetPasswordApi = async (data: any, id: any): Promise<Object> => {
  return (
    await api.patch(`User/${id}/password`, data)
  );
};

const logoutApi = async (): Promise<Object>  => {
  return await api.post(`Authentication/logout`, {});
};

export {
    loginApi,
    getUsersDataApi,
    getUserDetailApi,
    updateUserApi,
    userSaveApi,
    deleteUserApi,
    forgotPasswordApi,
    resetPasswordApi,
    twoFactor,
    logoutApi
}