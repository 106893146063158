import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select,  notification} from "antd";
import { useLocation } from "react-router-dom";
import { getFinanceInfoApi,updateFinanceApi} from "../../services/financeApi";
import {getTaxAdministartions,getDistrictsApi} from "../../services/commonApi"
import { useSelector} from 'react-redux';


const FinancialInstitutionUpdate = () => { 
  const { TextArea } = Input;
  const location = useLocation();
  const financeId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [financialInfo, setFinancialInfo] = useState<any>();
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const {provinces} = useSelector((state: any) => state.common);

  const onFinish =  async (values:any) => {
    const provinceName = provinces && provinces.filter((item:any) => {
      if(item.provinceId === values.invoiceProvince){
        return item
      }
      return 
    } );

    const userData : any = {
      name : values.title ? values.title : financialInfo?.name,
      accountingEmail : values.accountingEmail ? values.accountingEmail : financialInfo?.accountingEmail,
      invoiceProvince:  values.invoiceProvince ? provinceName[0].name : financialInfo?.invoiceProvince ,
      taxNumber: values.taxId ? values.taxId : financialInfo?.taxId,
      taxAdministration : values.taxAdministration ? values.taxAdministration : financialInfo?.taxAdministration,
      invoiceDistrict : values.invoiceDistrict ? values.invoiceDistrict : financialInfo?.invoiceDistrict,
      invoicePostCode : values.invoicePostCode ? values.invoicePostCode : financialInfo?.invoicePostCode,
      commissionRate : values.commissionRate ? values.commissionRate : financialInfo?.commissionRate,
      invoiceAddress : values.invoiceAddress ? values.invoiceAddress : financialInfo?.invoiceAddress,
      contractTerm : new Date().toISOString()
    };
  
    try {
        await updateFinanceApi(financeId, userData);
          notification.warning({
            message: `Başarıyla güncellenmiştir.`,
        });   
        window.location.reload();
        
      } catch (error: any) {
        console.log(error);
      }
  };

  
const getFinanceInfo =  async (financeId:any) => {
  try {
    const response: any = await getFinanceInfoApi(financeId);
    if (response) {
      setFinancialInfo(response.financialInstitution);
    }
    } catch (error: any) {
      console.log(error);
    }
}

const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['invoiceDistrict']);

  try {
    const response: any = await getDistrictsApi(dataId);
    if (response) {
      setDistrictList(response);
    }
  } catch (error: any) {
    console.log(error);
  }


  try {
    const response: any = await getTaxAdministartions(dataId);
    if (response) {
      setTaxAdministartion(response);
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
    if(financeId){
      getFinanceInfo(financeId);
    }
  },[financeId])


  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Finans Kurumu Bilgi Güncelleme</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Form
          form={form}
          name="user-update"
          className="default-form"
          onFinish={onFinish}
          labelCol={{
          span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          initialValues={{
            size: 'large',
          }}
          size={'large'}
          style={{
            width: '100%',
            display:'flex'
          }}
          >
       
          <div className="form-col-box" style={{paddingTop:'43px'}}>

            <Form.Item 
              label="Ünvan:"
              name="title"
            >
              <Input placeholder={financialInfo?.name} />
            </Form.Item>
            <Form.Item 
              label="Vergi Kimlik Numarası:" 
              name="taxId"
              >
              <Input placeholder={financialInfo?.taxId}/>
            </Form.Item>
            <Form.Item 
              label="Muhasebe E-mail Adresi:"
              name="accountingEmail"
            >
              <Input placeholder={financialInfo?.accountingEmail}  />
            </Form.Item>
            <Form.Item 
              label="İl:"
              name="invoiceProvince"
              >
              <Select 
                placeholder={financialInfo?.invoiceProvince} 
                onChange={(e:any) => getDistrictsTaxId(e)}
                optionFilterProp="children"
                showSearch
                >
                {
                 provinces && provinces.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.provinceId}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
           
        
            </div>
            <div className="form-col-box">
            <h4 style={{marginBottom:'20px', textDecoration:'underline'}}>Fatura Adresi</h4>
            <Form.Item 
              label="İlçe:"
              name="invoiceDistrict"
              >
              <Select  
                placeholder={financialInfo?.invoiceDistrict}  
                optionFilterProp="children"
                showSearch
                >
                {
                 districtList && districtList.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item}>{item}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <Form.Item 
              label="Vergi Dairesi:"
              name="taxAdministration"
              >
              <Select  
                optionFilterProp="children"
                showSearch
                placeholder={financialInfo?.taxAdministration}
                >
                {
                 taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  }): ''
                }
               
              </Select>
            </Form.Item>
            <Form.Item 
              label="Posta Kodu:"
              name="invoicePostCode"
              >
              <Input  placeholder={financialInfo?.invoicePostCode} />
            </Form.Item>
            <Form.Item 
              label="Komisyon Oranı:"
              name="commissionRate"
              >
              <Input placeholder={financialInfo?.commissionRate}  />
            </Form.Item>
            <Form.Item 
              label="Adres"
              name="invoiceAddress"
            >
              <TextArea
               rows={4} 
               placeholder={financialInfo?.invoiceAddress}
               />
            </Form.Item>
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Güncelle</Button>
            </Form.Item>
      
            </div>
        </Form>
        </Col>
      </Row>
    </main>
  );
};

export default FinancialInstitutionUpdate;
