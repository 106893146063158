import React, { useState, useEffect,useRef } from "react";
import { Row,  Col, Table , Button, DatePicker, Input, Space} from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getUnregisteredSuppliersApi,getUnregisteredBuyersTitleApi, getUnregisteredSuppliersTitleApi } from "../../services/buyerApi";
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';

function ApplicantSuppliers() {
 
  const [loading, setLoading] = useState(false);
  const [unregisteredSuppliers, setUnregisteredSuppliers] = useState<any>();
  const [buyersTitle, setBuyersTitle] = useState<any>();
  const [suppliersTitle, setSuppliersTitle] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const searchInput = useRef<InputRef>(null);
  // const today = new Date().toLocaleDateString("tr-TR");
  // const hours = new Date().getHours();
  // const minutes = new Date().getMinutes();

  const [filters, setFilters] = useState<any>({
    supplierCreatedDateBegin: '',
    supplierCreatedDateEnd: '',
    supplierTitles: '',
    buyerTitles: '',
    searchSupplierTitle:'',
    searchBuyerTitle:'',
    searchSupplierTaxNumber:'',
    searchBuyerTaxNumber:''
  });

  const getUnregisteredSuppliers  = async () => {  
    try {
      setLoading(true);
      const {
        supplierCreatedDateBegin,
        supplierCreatedDateEnd,
        supplierTitles,
        buyerTitles,
        searchSupplierTitle,
        searchBuyerTitle,
        searchSupplierTaxNumber,
        searchBuyerTaxNumber,
      } = filters;     
      const filterStr = `${supplierCreatedDateBegin ? `supplierCreatedDateBegin=${moment(supplierCreatedDateBegin).format('YYYY-MM-DD')}&` : ''}
        ${supplierCreatedDateEnd ? `supplierCreatedDateEnd=${moment(supplierCreatedDateEnd).format('YYYY-MM-DD')}&` : ''}
        ${buyerTitles ? buyerTitles : ''}
        ${supplierTitles ? supplierTitles : ''}
        ${searchSupplierTitle ? `searchSupplierTitle=${searchSupplierTitle}&` : ''}
        ${searchBuyerTitle ? `searchBuyerTitle=${searchBuyerTitle}&`: ''}
        ${searchSupplierTaxNumber ? `searchSupplierTaxNumber=${searchSupplierTaxNumber}&` : ''}
        ${searchBuyerTaxNumber ? `searchBuyerTaxNumber=${searchBuyerTaxNumber}&` : ''}
         `

      const response: any = await getUnregisteredSuppliersApi(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''), psize, page);
      if (response) {
        setLoading(false);
        setUnregisteredSuppliers(response?.unregisteredSuppliers?.data);
        setTotalDataCount(response?.unregisteredSuppliers?.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }
  const getUnregisteredBuyersTitle  = async () => {  
    try {
      const response: any = await getUnregisteredBuyersTitleApi();
      if (response) {
        setBuyersTitle(response?.buyerTitles)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 

  const getUnregisteredSuppliersTitle  = async () => {  
    try {
      const response: any = await getUnregisteredSuppliersTitleApi();
      if (response) {
        setSuppliersTitle(response?.suppliersTitles)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 
 
 const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      supplierCreatedDateBegin: '',
      supplierCreatedDateEnd: '',
      supplierTitles: '',
      buyerTitles: '',
      searchSupplierTitle:'',
      searchBuyerTitle:'',
      searchSupplierTaxNumber:'',
      searchBuyerTaxNumber:''
    })
  };

  const checkDate = (checkDate: any, date1: any, date2:any) => {
    return moment(moment(checkDate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

 
  const getColumnTimeProps = (dataIndex: string) => ({ 
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }} >
        <div style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</div>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              supplierCreatedDateBegin: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.supplierCreatedDateBegin,
              supplierCreatedDateEnd: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.supplierCreatedDateEnd,
              supplierTitles: filters.supplierTitles,
              buyerTitles: filters.buyerTitles,
              searchSupplierTitle:filters.searchSupplierTitle,
              searchBuyerTitle:filters.searchBuyerTitle,
              searchSupplierTaxNumber:filters.searchSupplierTaxNumber,
              searchBuyerTaxNumber:filters.searchBuyerTaxNumber,
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined  style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  interface DataType {
    key: string;
    buyerTaxId: string;
    supplierTaxId: string
  }
  
  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      searchBuyerTaxNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      searchBuyerTaxNumber: '',
    })
  };

  const handleSearch2 = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      searchSupplierTaxNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice2 = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      searchSupplierTaxNumber:''
    })
  };

  const getBuyerVKNSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN no'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value:any, record:any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getSupplierVKNSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='VKN No'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice2(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });


  const handleChange = (pagination: any, _filters: any) => {
     setpage(pagination.current);
     setPsize(pagination.pageSize);
     setFilters({
       ...filters,
       buyerTitles : _filters?.buyerTitle !== null ? _filters?.buyerTitle.map((item:any) => `buyerTitles=${item}&`).toString().split(',').join('') : filters.buyerTitle="",
       supplierTitles : _filters?.supplierTitle !== null ? _filters?.supplierTitle.map((item:any) => `supplierTitles=${item}&`).toString().split(',').join('') : filters.supplierTitle="",
     })
     setFilteredInfo(_filters);
   };

  useEffect(() => {
    getUnregisteredSuppliers();
    getUnregisteredBuyersTitle();
    getUnregisteredSuppliersTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [page,psize, filters])

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  }

  // const xlsxReport = async () => {
  //   try {
  //     setLoading(true);
  //     const {
  //       createdDateBegin,
  //       createdDateEnd,
  //       invoiceTermBegin,
  //       invoiceTermEnd,
  //       invoiceDateBegin,
  //       invoiceDateEnd,
  //       invoiceNumber,
  //       buyerTitles,
  //       supplierTitles,
  //       applicationNumber,
  //       invoiceStatuses,
  //       financialInstitutionName
  //     } = filters;     
  //     const filterStr = `${createdDateBegin ? `createdDateBegin=${createdDateBegin}&` : ''}
  //       ${createdDateEnd ? `createdDateEnd=${createdDateEnd}&` : ''}
  //       ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
  //       ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}
  //       ${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
  //       ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&`: ''}
  //       ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
  //       ${buyerTitles ? buyerTitles : ''}
  //       ${supplierTitles ? supplierTitles : ''}
  //       ${applicationNumber ? `applicationNumber=${applicationNumber}&` : ''}
  //       ${invoiceStatuses ? invoiceStatuses  : ''}
  //       ${financialInstitutionName ? financialInstitutionName : ''}
  //       `

  //     const response: any = await getInvoicesXlsxApi(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''), totalDataCount, page);
  
  //     if (response) {
  //         const link = document.createElement('a');
  //         link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(response.xlsxReport);
  //         link.setAttribute('download', `depar.io -  fatura raporu ${today} ${hours}.${minutes}.xlsx`); 
  //         link.style.display = 'none';
  //         document.body.appendChild(link);      
  //         link.click();      
  //         document.body.removeChild(link);
  //         setLoading(false);
  //     }
  //   } catch (error: any) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // }

  const columns: any = [
    {
      title: "Alıcı",
      dataIndex: "buyerTitle",
      key: "buyerTitle",
      width:140,
      filters: buyersTitle && buyersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.buyerTitle) || null,
      onFilter: (value:string, record:any) => record.buyerTitle.includes(value),
      ellipsis: true,
      filterSearch: true,
    },
    {
      title: "Alıcı VKN",
      dataIndex: "buyerTaxId",
      key: "buyerTaxId",
      width:100,
      ...getBuyerVKNSearchProps('buyerTaxId'),
    },
    {
      title: "Tedarikçi İsim",
      dataIndex: "nameSurname",
      key: "nameSurname",
      width:140,
    },
    {
      title: "Tedarikçi E-posta",
      dataIndex: "email",
      key: "email",
      width:120,
      align: 'center',
    },
    {
      title: "Tedarikçi Telefon Numarası",
      dataIndex: "gsmNumber",
      key: "gsmNumber",
      width:120,
      align: 'center',
    },
    {
      title: "Tedarikçi Ünvanı",
      dataIndex: "supplierTitle",
      key: "supplierTitle",
      width:120,
      align: 'center',
      filters: suppliersTitle && suppliersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.supplierTitle) || null,
      onFilter: (value: string, record: any) => record.supplierTitle.includes(value),
      ellipsis: true,
      filterSearch: true,
    },
    {
      title: "Tedarikçi VKN",
      dataIndex: "supplierTaxId",
      key: "supplierTaxId",
      width:100,
      align: 'center',
      ...getSupplierVKNSearchProps('supplierTaxId'),
    },
    {
      title: "Alıcının Tedarikçiyi Tanımlama Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width:150,
      align: 'center',
      ...getColumnTimeProps('createdDate'),
    },
  ];

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Aday Tedarikçiler</h3>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24} style={{textAlign:'right'}}>
          <Button type="primary"  
            icon={<DownloadOutlined />} 
            style={{backgroundColor:'#008000'}}
            onClick={xlsxReport}
            >
            Excel İndir
          </Button>
        </Col>
      </Row> */}
      <Row>
      <Col span={24} style={{ margin: "0px auto" }}>    
          <Table
            className="w-100 invoiceTable"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,                          
              showSizeChanger: true,
              current: page,
              pageSize:psize,
              total:totalDataCount, 
              onShowSizeChange: handleShowSize,
              
            }}
            onChange={handleChange}
            loading={loading} 
            dataSource={unregisteredSuppliers}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
    </main>
  );
};

export default ApplicantSuppliers;
