import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select,  notification,Checkbox} from "antd";
import { useLocation } from "react-router-dom";
import { getSupplierInfoApi,updateSupplierApi} from "../../services/supplierApi";
import {getTaxAdministartions,getDistrictsApi, getCompaniesApi} from "../../services/commonApi"
import { useSelector} from 'react-redux';
import {validateVkn,validateEmail,validateGsmNumber} from "../../helpers/validators";


const AccountTransactionsUpdate = () => { 
 
  const location = useLocation();
  const supplierId = location.pathname.split('/')[2];
  const [form] = Form.useForm();
  const [supplierlInfo, setSupplierInfo] = useState<any>();
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);


  const [districtInput, setDistrictInput] = useState<any>([]);
  const [taxName, setTaxName] = useState<any>([]);
  const [province, setProvince] = useState<any>([]);
  const [check, setCheck] = useState<any>(false);

  const {provinces} = useSelector((state: any) => state.common);

  const onFinish =  async (values:any) => {
 
    const userData : any = {
      name : values.name !==  undefined || '' ? values.name : supplierlInfo?.name,
      surname : values.surname !==  undefined || '' ? values.surname : supplierlInfo?.surname,
      email : values.email !==  undefined || '' ? values.email : supplierlInfo?.email,
      phone : values.phone !==  undefined || '' ? values.phone : supplierlInfo?.phone,
      taxNumber: values.taxId !==  undefined  || '' ? values.taxId : supplierlInfo?.taxId,
      title: values.title !==  undefined || '' ? values.title : supplierlInfo?.title,
      taxAdministration : values.taxAdministration  !==  undefined || '' ? values.taxAdministration : supplierlInfo?.taxAdministration,
      province: values.province !==  undefined  || '' ? values.province: supplierlInfo?.province,
      district: values.district !==  undefined || '' ? values.district : supplierlInfo?.district,
      companyType : values.companyType !==  undefined || '' ? values.companyType : supplierlInfo?.companyType,
      generalUsagePermission: check
    };

    try {
        await updateSupplierApi(supplierId, userData);
          notification.warning({
            message: `Başarıyla güncellenmiştir.`,
        });  
         window.location.reload();
        
      } catch (error: any) {
        console.log(error);
      }
  };
  
const getSupplierInfo =  async (supplierId:any) => {
  try {
    const response: any = await getSupplierInfoApi(supplierId);
      if (response) {
        setSupplierInfo(response.supplier);
        setDistrictInput(response.supplier.district);
        setTaxName(response.supplier.taxAdministration)
        setProvince(response.supplier.province);
      }
    
    } catch (error: any) {
      console.log(error);
    }
}

const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['district']);
  setDistrictInput('');
  setTaxName('')
  const provinceId = provinces.filter((item:any) => item.name === dataId)
    try {
      const response: any = await getDistrictsApi(provinceId[0].provinceId);
      if (response) {
        setDistrictList(response);
      }
    } catch (error: any) {
      console.log(error);
    }

    try {
      const response: any = await getTaxAdministartions(provinceId[0].provinceId);
      if (response) {
        setTaxAdministartion(response);
      }
    } catch (error: any) {
      console.log(error);
    }
}

const getCompanies = async () => {
  const response = await getCompaniesApi();
  if (response) {
      setCompanies(response);
  }  
};

  const onChange = (checkedValues:any) => {
    setCheck( checkedValues.target.checked)
  };
  
  useEffect(() => {
    setCheck(supplierlInfo?.generalUsagePermission)
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[supplierlInfo])

  useEffect(() => {
    if(supplierId){
      getSupplierInfo(supplierId);
      getCompanies();
    }
  },[supplierId])

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Tedarikçi Güncelleme</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Form
          form={form}
          name="user-update"
          className="default-form"
          onFinish={onFinish}
          labelCol={{
          span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          initialValues={{
            size: 'large',
          }}
          size={'large'}
          style={{
            width: '100%',
            display:'flex'
          }}
          >
       
          <div className="form-col-box" style={{paddingTop:'0'}}>

            <Form.Item 
              label="Ünvan:"
              name="title"
            >
              <Input placeholder={supplierlInfo?.title} />
            </Form.Item>

            <Form.Item 
              label="Şirket Türü:"
              name="companyType"
              >
              <Select 
                placeholder={supplierlInfo?.companyType} 
                optionFilterProp="children"
                showSearch
                >
                {
                 companies && companies.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>

            <Form.Item 
              label="Vergi Kimlik Numarası:" 
              name="taxId"
              rules={[
                {
                  validator: (_, value) =>  validateVkn(value ? value : supplierlInfo?.taxId )
                },
              ]}
              >
              <Input placeholder={supplierlInfo?.taxId}/>
            </Form.Item>
            <Form.Item 
              label="Ad:" 
              name="name"
              >
              <Input placeholder={supplierlInfo?.name}/>
            </Form.Item>
            <Form.Item 
              label="Soyad:" 
              name="surname"
              >
              <Input placeholder={supplierlInfo?.surname}/>
            </Form.Item>

            </div>
            <div className="form-col-box">
            <Form.Item 
              label="E-mail Adresi:"
              name="email"
              rules={[
                {
                  validator: (_, value) =>  validateEmail(value ? value : supplierlInfo?.email )
                },
              ]}
            >
              <Input placeholder={supplierlInfo?.email}  />
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="phone"
              rules={[
                {
                  validator: (_, value) =>  validateGsmNumber(value ? value : supplierlInfo?.phone )
                },
              ]}
            >
              <Input placeholder={supplierlInfo?.phone}  />
            </Form.Item>
            <Form.Item 
              label="İl:"
              name="province"
              >
              <Select 
                placeholder={province && province} 
                onChange={(e:any) => getDistrictsTaxId(e)}
                optionFilterProp="children"
                showSearch
                >
                {
                 provinces && provinces.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <Form.Item 
              label="İlçe:"
              name="district"
              >
              <Select  
                placeholder={districtInput}  
                optionFilterProp="children"
                showSearch
                >
                {
                 districtList && districtList.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item}>{item}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
            <Form.Item 
              label="Vergi Dairesi:"
              name="taxAdministration"
              >
              <Select  
                optionFilterProp="children"
                showSearch
                placeholder={taxName}
                >
                {
                 taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  }): ''
                }
               
              </Select>
            </Form.Item>
            
              <Form.Item name="generalUsagePermission" >
                  <Checkbox  checked={check}  onChange={onChange}
                  >Genel kullanım izni</Checkbox>
                </Form.Item>
            
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit">Güncelle</Button>
            </Form.Item>
      
            </div>
        </Form>
        </Col>
      </Row>
    </main>
  );
};

export default AccountTransactionsUpdate;
