import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, notification} from "antd";
import { financeUserSaveApi, getCorporationsListApi} from "../../services/financeApi";
import { useSelector,useDispatch} from 'react-redux';
import {setFinancialInstitutionsData} from "../../reducers/financeSlice";
import {validateGsmNumber,validateEmail} from "../../helpers/validators"

const CorporationUserSave = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [financialId, setFinancialId] = useState<any>();
  const [userRole, setUserRole] = useState<any>();
  const {financialInstitutions} = useSelector((state: any) => state.finance);

  const onFinish =  async (values:any) => {
    setLoading(true);
    const userData : any = {
        name : values.name,
        surname : values.surname,
        gsmNumber : values.gsmNumber,
        email : values.email,
        isPremium: true,
        userRole: userRole
    };

      try {
        const response: any = await financeUserSaveApi(financialId, userData);
          if (response) {
            notification.success({
              message: `Başarıyla yeni kayıt oluşturuldu.`,
          }); 
          form.resetFields();
          setLoading(false);
          }else{
            notification.warning({
              message: `Bilgilerinizi kontrol ederek yeniden deneyiniz.`,
          }); 
          }
        } catch (e: any) {
          setLoading(false);
          if(e.status === 409 && e.data.Type.includes('FinancialInstitutionUserFoundException')){
            notification.warning({
                message: `Bu kullanıcı platforma daha önce tanımlanmıştır. Farklı bir e-mail adresi ile ilerleyebilirsiniz.`,
            });
        }
      }
  };

const getFinanceId = (financeName:any) => {
  const financeId = financialInstitutions.filter((item:any) => {
    if(item.name === financeName){
      return item.id
    }
    return
  })
 
  setFinancialId(financeId[0].id)
}


const getUserRole = (userRole:any) => {
    setUserRole(userRole)
}

const getCorporationsList =  async () => {
  try {
    const response: any = await getCorporationsListApi();
    if (response) {
      dispatch(setFinancialInstitutionsData(response.financialInstitutions));
     
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
      getCorporationsList();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[])

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Finans Kullanıcısı Tanımla</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
              form={form}
              name="user-update"
              className="default-form"
              onFinish={onFinish}
              labelCol={{
              span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="vertical"
              initialValues={{
                size: 'large',
              }}
            
              size={'large'}
              style={{
                maxWidth: 600,
              }}
          >
            <Form.Item 
              label="Adı:"
              name="name"
              rules={[
                {
                    required: true,
                    message: 'Lütfen ad giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Soyadı:"
              name="surname"
              rules={[
                {
                    required: true,
                    message: 'Lütfen soyad giriniz.',
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Telefon Numarası:"
              name="gsmNumber"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateGsmNumber(value)
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="E-posta Adresi:"
              name="email"
              rules={[
                {
                  required: true,
                  validator: (_, value) => validateEmail(value)
                },
              ]}
              >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Finansal Kurum:"
              name="financialInstitutionName"
              rules={[
                {
                    required: true,
                    message: 'Lütfen finans kurumu seçiniz.',
                },
              ]}
              >
              <Select onChange={getFinanceId}
                >
                {
                 financialInstitutions && financialInstitutions.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
            </Form.Item>
              <Form.Item
                  label="Kullanıcı Rolü"
                  name="financialInstitutionRole"
                  rules={[
                      {
                          required: true,
                          message: 'Lütfen finans kurumu rolü seçiniz.',
                      },
                  ]}
              >
                  <Select onChange={getUserRole}
                  >
                      <Select.Option key="1" value="API_USER">API Kullanıcısı</Select.Option>
                      <Select.Option key="2" value="UI_USER">UI Kullanıcısı</Select.Option>
                  </Select>
              </Form.Item>
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit" loading={loading}>Kaydet</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </main>
  );
};

export default CorporationUserSave;
