import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Spin,  notification, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import {  getTemplateVariableApi, getTemplateVariableDeleteteApi, getTemplatesNameApi, getTemplateVariableUpdateApi, setTemplatesSaveApi} from "../../services/managementApi";

const TemplateEditVariables = () => {
  const [form3] = Form.useForm();
  const [form1] = Form.useForm();
  const location = useLocation();
  const url = location.pathname.split('/');
  const templateId = url[2];
  const [isShowRow, setIsShowRow] = useState<any>([]);
  const [show, setShow] = useState<any>(false);
  const [variable, setVariable] = useState<any>([]);
  const [templateName, setTemplateName] = useState<any>([]);
  const [variableName, setVariableName] = useState<any>('');
  const [variableDefinition, setVariableDefinition] = useState<any>('');
  const variables1: { name: string, description: string }[] = [];
  const [formLayout, setFormLayout] = useState('horizontal');
  const onFormLayoutChange = ({ layout }: any) => {
    setFormLayout(layout);
  };
  const [loading, setLoading] = useState(false);

  const getTemplateList = async () => {
    try {
      setLoading(true)
      const response: any = await getTemplatesNameApi();
      if(response){
        setLoading(false);
        setTemplateName(response?.templates?.filter((item:any) => item.id === templateId ));
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

  const getTemplateVariable = async () => {
    try {
      setLoading(true)
      const response: any = await getTemplateVariableApi(templateId);
      if(response){
        setLoading(false);
        // setVariableData(response);
        setVariable(response)
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  }

const variableUpdate = async (vname:any, vdefinition:any, id:any, index:any) => {
 const nameData:any = vname !== ''  ? vname : (variable[index].name).replace(/[{}@]/g, '')
 const definitionData :any = vdefinition !== '' ? vdefinition : variable[index].description

  let data:any = {
    name: `@{${nameData}}`, 
    type:'string',
    required:true,
    description: definitionData
  }

  try {
    setLoading(true)
    await getTemplateVariableUpdateApi(templateId, id, data);
    setLoading(false);
    notification.success({
      message: `Başarıyla güncellenmiştir.`,
    });
    getTemplateVariable();
    setVariableDefinition('');
    setVariableName('');
    form3.resetFields();

  } catch (e: any) {
    console.log(e);
    setLoading(false);
  }
}

const variableDelete = async (variableId:any) => {
  try {
    setLoading(true)
    await getTemplateVariableDeleteteApi(templateId, variableId);
    setLoading(false);
    notification.success({
      message: `Başarıyla silinmiştir.`,
    });
    getTemplateVariable()

  } catch (e: any) {
    console.log(e);
    setLoading(false);
  }
}

const showForm = () => {
  setIsShowRow([...isShowRow, { variableName: '', variableDefinition: '' }])
}

const setVariablesSave = async (val:any) => {
  for (let key in val) {
    if (key.startsWith('variableName')) {
      const index:any = key.replace('variableName', '');
      if (!variables1[index]) {
        variables1[index] = { name: '', description: '' };
      }
      variables1[index].name = val[key];
    }
    
    if (key.startsWith('variableDefinition')) {
      const index:any = key.replace('variableDefinition', '');
      if (!variables1[index]) {
        variables1[index] = { name: '', description: '' };
      }
      variables1[index].description = val[key];
    }
  }
 
  const variables: any = [];
  variables1.map((item:any) => {
      return(
        variables.push({
      name: item.name,
      type: "string",
      description: item.description,
      required: true
    })
  )
  })

  if(variables.length>0){  
    try{
      setLoading(true);
      const response: any = await setTemplatesSaveApi(templateId, {variables});
      if(response){
        notification.success({
          message: `Başarıyla şablon güncellenmiştir.`,
        });
        form1.resetFields();
        form3.resetFields();
        setVariable([]);
        setIsShowRow([]);
        setLoading(false);
        getTemplateVariable();
        setShow(false)
      }
    }catch(e:any){
      console.log(e)
    }
  }
}


  useEffect(() => {
    getTemplateList();
    getTemplateVariable();
     // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
     if(isShowRow.length>0){
      setShow(true)
     } 
  }, [isShowRow])
 
  return (
    <main>
       <Spin spinning={loading} >
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title">Şablon Etiket Düzenle</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
            <Form
              form={form3}
              layout="vertical"
              onValuesChange={onFormLayoutChange}
              style={{
                maxWidth: formLayout === 'inline' ? 'none' : 600,
              }}
              autoComplete="off"
            >
              <Form.Item
                label="Şablon Adı "
                name="templateId"
              >
                <Input placeholder={templateName[0]?.name}  disabled={true} />
              </Form.Item>
             
              <Divider />
              {
                variable.map((item: any, index: any) => {
                  return (
                    <>
                      <Form.Item
                        name={`variableName${index + 1}`}
                        label='Değişken Adı'>
                        <Input placeholder={(item.name).replace(/[{}@]/g, '')}  onChange={(e) => setVariableName(e.target.value)} />
                      </Form.Item>

                      <Form.Item
                        name={`variableDefinition${index + 1}`}
                        label='Değişken Tanımı'>
                        <Input placeholder={item.description} onChange={(e) => setVariableDefinition(e.target.value)} />
                      </Form.Item>

                      <div style={{display:'flex', gap:'20px'}}>
                        <Form.Item>
                          <Button type="primary" onClick={() => variableUpdate(variableName, variableDefinition, item.id, index)}  style={{ marginTop: '20px' }} >
                            Güncelle
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" onClick={() => variableDelete( item.id)}   style={{ marginTop: '20px' }} >
                            Sil
                          </Button>
                        </Form.Item>
                      </div>
                      <Divider />
                    </>
                  )
                })
              }
            </Form>
            <Form
              form={form1}
              layout="vertical"
              onValuesChange={onFormLayoutChange}
              onFinish={setVariablesSave}
              style={{
                maxWidth: formLayout === 'inline' ? 'none' : 600,
              }}
              autoComplete="off"
            >
              {
                isShowRow.map((item: any, index: any) => {
                  return (
                    <>
                      <Form.Item
                        name={`variableName${index + 1}`}
                        label='Değişken Adı'>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={`variableDefinition${index + 1}`}
                        label='Değişken Tanımı'>
                        <Input />
                      </Form.Item>
                      <Divider />
                    </>
                  )
                })
              }
              <Form.Item>
                <Button type="primary" onClick={showForm} style={{ marginTop: '20px' }} >
                  <PlusOutlined /> Yeni Şablon Etiketi Ekle
                </Button>
              </Form.Item>
              {
                show > 0 ? (
                  <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading} style={{ marginTop: '20px' }}  >
                    Kaydet
                  </Button>
                </Form.Item>
                ): ''
              }
             
            </Form>
        </Col>
      </Row>
      </Spin>
    </main>
  );
};

export default TemplateEditVariables;
