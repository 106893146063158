import React from 'react';
import { Col, Row, Typography } from 'antd';
import LOGO_WHITE from '../assets/depario-white.png';


const { Text } = Typography;

function Footer() {
    return (
     <footer>
           <Row className="footer-box">
            <Row className="footer-content">
                <Col md={6} lg={6} >
                    <div style={{  textAlign: 'left'}}>
                        <img src={LOGO_WHITE} alt="depar logo" style={{width:'200px'}} />
                    </div>                   
                </Col>     
                <Col md={18} lg={18}>
                    <Text style={{textAlign: 'center', color: '#fff', width: '100%'}}>
                    © 2024 – depar.io bir Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık A.Ş. kuruluşudur. Tüm hakları saklıdır.
                    </Text>
                </Col>         
            </Row>

            
        </Row>
     </footer>
    );
}

export default Footer;
