import React, { useState, useEffect,useRef } from "react";
import { Row,  Col, Table , Button, DatePicker, Input, Space} from "antd";
import { CalendarOutlined, SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from 'moment';
import { getInvoicesApi,getBuyersTitleApi, getSuppliersTitleApi, getInvoicesXlsxApi } from "../../services/buyerApi";
import { getCorporationsListApi } from "../../services/financeApi";
import { convertFloatDotSeperated } from "../../helpers/common";
import {statusInvoiceMapByValue} from "../../helpers/common";
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/es/table';


function InvoicesStatus() {
 
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<any>();
  const [buyersTitle, setBuyersTitle] = useState<any>();
  const [suppliersTitle, setSuppliersTitle] = useState<any>();
  const [financialTitle, setFinancialTitle] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [filteredInfo, setFilteredInfo] = useState<any>(null);
  const searchInput = useRef<InputRef>(null);
  const today = new Date().toLocaleDateString("tr-TR");
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();

  const [filters, setFilters] = useState<any>({
    createdDateBegin: '',
    createdDateEnd: '',
    invoiceTermBegin: '',
    invoiceTermEnd: '',
    invoiceDateBegin:'',
    invoiceDateEnd:'',
    invoiceNumber: '',
    buyerTitles: '',
    supplierTitles: '',
    applicationNumber:'',
    invoiceStatuses:'',
    financialInstitutionName:''
  });

 const statusInvoice = [
    {    
      value:'YUKLENDI', 
      color:'#0b3163', 
      text:'Yüklendi',
      desc:'Yüklendi.'
    },
    {
      value:'VADESI_GECTI',
      color:'#faad14',
      text:'Vadesi Geçti',
      desc:'Vadesi Geçti.',
    },
    {
      value:'ISKONTO_T_BULUNULDU',
      color:'#b7b700',
      text:'İskonto Talebi Bulundu',
      desc:'İskonto Talebi Bulundu.',
     },
    {
      value:'TEMLIK_EDILDI',
      color:'#0ee373',
      text:'Temlik Edildi',
      desc:'Temlik Edildi.',
    },
    {
      value:'REDDEDILDI',
      color:'#ff0000',
      text:'Reddedildi',
      desc:'Reddedildi.',
    },
    { 
      value:'ON_ODEME_YAPILDI', 
      color:'#08d92c', 
      text:'Ön Ödeme Alındı',
      desc:'Ön Ödeme Alındı.',
    },
    {
      value:'ODENDI', 
      color:'#40a9ff', 
      text:'Ödendi',
      desc:'Ödendi.'
    }  
 ];

  const getInvoices  = async () => {  
    try {
      setLoading(true);
      const {
        createdDateBegin,
        createdDateEnd,
        invoiceTermBegin,
        invoiceTermEnd,
        invoiceDateBegin,
        invoiceDateEnd,
        invoiceNumber,
        buyerTitles,
        supplierTitles,
        applicationNumber,
        invoiceStatuses,
        financialInstitutionName
      } = filters;     
      const filterStr = `${createdDateBegin ? `createdDateBegin=${moment(createdDateBegin).format("YYYY-MM-DDTHH:mm:ss")}&` : ''}
        ${createdDateEnd ? `createdDateEnd=${moment(createdDateEnd).format("YYYY-MM-DDTHH:mm:ss")}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}
        ${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
        ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&`: ''}
        ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
        ${buyerTitles ? buyerTitles : ''}
        ${supplierTitles ? supplierTitles : ''}
        ${applicationNumber ? `applicationNumber=${applicationNumber}&` : ''}
        ${invoiceStatuses ? invoiceStatuses  : ''}
        ${financialInstitutionName ? financialInstitutionName : ''}
         `

      const response: any = await getInvoicesApi(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''), psize, page);

      if (response) {
        setLoading(false);
        setInvoices(response.invoices.data);
        setTotalDataCount(response.invoices.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }
  const getBuyersTitle  = async () => {  
    try {
      const response: any = await getBuyersTitleApi();
      if (response) {
        setBuyersTitle(response.buyersTitles)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 

  const getSuppliersTitle  = async () => {  
    try {
      const response: any = await getSuppliersTitleApi();
      if (response) {
        setSuppliersTitle(response.supplierTitles)
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 

  const getFinancialTitle  = async () => {  
    try {
      const response: any = await getCorporationsListApi();
      if (response) {
        let newData: any = [];
        newData =  response.financialInstitutions.map((item:any)=> {
    return(
            {
              name: item.name,
              id: item.id
            } 
          )
      } 
    )
    setFinancialTitle(newData)
    }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  } 
 
 const handleReset = (clearFilters: any) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      createdDateBegin: '',
      createdDateEnd: '',
      invoiceTermBegin: '',
      invoiceTermEnd: '',
      invoiceDateEnd:'',
      invoiceDateBegin:'',
      invoiceNumber: '',
      buyerTitles: '',
      supplierTitles: '',
      applicationNumber:'',
      invoiceStatuses:'',
      financialInstitutionName:''
    })
  };

  const checkDate = (checkDate: any, date1: any, date2:any) => {
    return moment(moment(checkDate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
  }

 
  const getColumnTimeProps = (dataIndex: string) => ({ 
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}: any) => (
      <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }} >
        <div style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih seçin veya aralık girin</div>
        <DatePicker.RangePicker
          onChange={(e: any) => {
            setSelectedKeys(e.length ? [e] : [])
            setFilters({
              ...filters,
              createdDateBegin: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.createdDateBegin,
              createdDateEnd: (dataIndex === 'createdDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.createdDateEnd,
              invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
              invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
              invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
              invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
              invoiceNumber: filters.invoiceNumber,
              buyerTitles: filters.buyerTitles,
              supplierTitles:filters.supplierTitles,
              applicationNumber:filters.applicationNumber,
              invoiceStatuses:filters.invoiceStatuses,
              financialInstitutionName:filters.financialInstitutionName,
            })
          }}
          allowClear={false}
          style={{ width: 190 }}
          value={selectedKeys[0]}
          format="YYYY-MM-DD"
        />
        <div style={{ marginTop: 15 }}>
          <Button
            role="reset"
            style={{ width: 90 }}
            onClick={() => handleReset(clearFilters)}
            size="small"
          >
            Sıfırla
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined  style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilter: (value: any, record: any) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : "",
    render: (text: string) => moment(text).format("DD-MM-YYYY")
  });

  interface DataType {
    key: string;
    invoiceNumber: string;
    applicationNumber: string
  }
  
  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      invoiceNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      invoiceNumber: '',
    })
  };

  const handleSearch2 = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
     setFilters({
      ...filters,
      applicationNumber: selectedKeys[0],
    })
  };

  const handleResetInvoice2 = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo(null);
    setFilters({
      ...filters,
      applicationNumber:''
    })
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='Fatura No'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value:any, record:any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getApplicationNumberProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder='Başvuru No'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleResetInvoice2(clearFilters)}
            size="small"
            style={{ width: 90 }}
            className="searc-btn-table"
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownOpenChange: (visible:any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });


  const handleChange = (pagination: any, _filters: any) => {
     setpage(pagination.current);
     setPsize(pagination.pageSize);
     setFilters({
       ...filters,
       buyerTitles: _filters?.buyerTitle !== null ? _filters?.buyerTitle.map((item:any) => `buyerTitles=${item}&`).toString().split(',').join('') : filters.buyerTitle="",
       supplierTitles: _filters?.invoiceOwnerTitle !== null ? _filters?.invoiceOwnerTitle.map((item:any) => `supplierTitles=${item}&`).toString().split(',').join('')  : filters.suppliersTitle = "",
       invoiceStatuses: _filters?.statusCode !== null ? _filters?.statusCode.map((item:any) => `invoiceStatuses=${item}&`).toString().split(',').join('')  : filters.invoiceStatuses = "",
       financialInstitutionName:  _filters?.financialInstitutionName !== null ? _filters?.financialInstitutionName.map((item:any) => `financialInstitutionIds=${item}&`).toString().split(',').join('') : filters.financialInstitutionName = "",
     })
     setFilteredInfo(_filters);
   };

  
  useEffect(() => {
    getInvoices();
    getBuyersTitle();
    getSuppliersTitle();
    getFinancialTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [page,psize, filters])

  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  }

  const xlsxReport = async () => {
    try {
      setLoading(true);
      const {
        createdDateBegin,
        createdDateEnd,
        invoiceTermBegin,
        invoiceTermEnd,
        invoiceDateBegin,
        invoiceDateEnd,
        invoiceNumber,
        buyerTitles,
        supplierTitles,
        applicationNumber,
        invoiceStatuses,
        financialInstitutionName
      } = filters;     
      const filterStr = `${createdDateBegin ? `createdDateBegin=${createdDateBegin}&` : ''}
        ${createdDateEnd ? `createdDateEnd=${createdDateEnd}&` : ''}
        ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
        ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}
        ${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
        ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&`: ''}
        ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
        ${buyerTitles ? buyerTitles : ''}
        ${supplierTitles ? supplierTitles : ''}
        ${applicationNumber ? `applicationNumber=${applicationNumber}&` : ''}
        ${invoiceStatuses ? invoiceStatuses  : ''}
        ${financialInstitutionName ? financialInstitutionName : ''}
        `

      const response: any = await getInvoicesXlsxApi(filterStr.trim().replaceAll(/\n/g,'').replace(/\s{2,}/g,''), totalDataCount, page);
  
      if (response) {
          const link = document.createElement('a');
          link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + encodeURIComponent(response.xlsxReport);
          link.setAttribute('download', `depar.io -  fatura raporu ${today} ${hours}.${minutes}.xlsx`); 
          link.style.display = 'none';
          document.body.appendChild(link);      
          link.click();      
          document.body.removeChild(link);
          setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }


  const columns: any = [
    {
      title: "Fatura No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: 'left',
      width:120,
      ...getColumnSearchProps('invoiceNumber'),
     
    },
    {
      title: "Alıcı",
      dataIndex: "buyerTitle",
      key: "buyerTitle",
      width:140,
      filters: buyersTitle && buyersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.buyerTitle) || null,
      onFilter: (value: string, record: any) => record.buyerTitle.includes(value),
      ellipsis: true,
    },
    {
      title: "Tedarikçi",
      dataIndex: "invoiceOwnerTitle",
      key: "invoiceOwnerTitle",
      width:140,
      filters: suppliersTitle && suppliersTitle.map((item:any) =>{ return {text: item, value: item} }),
      filteredValue: (filteredInfo && filteredInfo.invoiceOwnerTitle) || null,
      onFilter: (value: string, record: any) => record.invoiceOwnerTitle.includes(value),
      ellipsis: true,
    },
    {
      title: "Fatura Yük. Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      width:120,
      align: 'center',
      ...getColumnTimeProps('createdDate'),
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width:120,
      align: 'center',
      ...getColumnTimeProps('invoiceDate'),
    },
    {
      title: "Fatura Vadesi",
      dataIndex: "invoiceTerm",
      key: "invoiceTerm",
      width:120,
      align: 'center',
      ...getColumnTimeProps('invoiceTerm'),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      width:100,
      render: convertFloatDotSeperated,
      align: 'center'
    },
    {
      title: "Fatura Durumu",
      dataIndex: "statusCode",
      key: "statusCode",
      width:140,
      filters: statusInvoice || [],
      filteredValue: (filteredInfo && filteredInfo.statusCode) || null,
      onFilter: (value: string, record: any) => record.statusCode.includes(value),
      ellipsis: true,
      render: (value: any, id:any) => (
          <span style={{ color: statusInvoiceMapByValue[value]?.color, fontWeight: 'bold' }}>
              { statusInvoiceMapByValue[value].text}
          </span>
      ),
    },
    {
      title: "Başvuru Numarası ",
      dataIndex: "applicationNumber",
      key: "applicationNumber",
      width:120,
      ...getApplicationNumberProps('applicationNumber'),
    },
    {
      title: "Finans Kurumu",
      dataIndex: "financialInstitutionName",
      key: "financialInstitutionName",
      width:160,
      filters: financialTitle && financialTitle.map((item:any) =>{ return {text: item.name, value: item.id} }),
      filteredValue: (filteredInfo && filteredInfo.financialInstitutionName) || null,
      ellipsis: true,
    },
  ];

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title">Faturaların Durumu</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{textAlign:'right'}}>
          <Button type="primary"  
            icon={<DownloadOutlined />} 
            style={{backgroundColor:'#008000'}}
            onClick={xlsxReport}
            >
            Excel İndir
          </Button>
        </Col>
      </Row>
      <Row>
      <Col span={24} style={{ margin: "0px auto" }}>    
          <Table
            className="w-100 invoiceTable"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,                          
              showSizeChanger: true,
              current: page,
              pageSize:psize,
              total:totalDataCount, 
              onShowSizeChange: handleShowSize,
              
            }}
            onChange={handleChange}
            loading={loading} 
            dataSource={invoices}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
    </main>
  );
};

export default InvoicesStatus;
